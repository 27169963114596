import React from 'react';
import { Image, Text, TextInput, View, ScrollView, Keyboard } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button, Icon } from 'react-native-elements';
import { useFormik } from 'formik';
import { Picker } from '@react-native-picker/picker';
import { storePartnerCreditCard, getPartner } from 'services/partnerService';
import useChangeAuthData from 'hooks/useChangeAuthData';
import AppContext from 'contexts/AppContext';
import PartnerCreditCardData from 'types/models/PartnerCreditCardData';
import PartnerCreditCardFormScreenProps from 'types/screens/PartnerCreditCardFormScreenProps';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import partnerCreditCardFormSchema from 'forms/schemas/partnerCreditCardFormSchema';
import Footer from 'components/Footer';
import SuccessModal from 'components/SuccessModal';
import { styles } from 'styles/screens/partnerCreditCardFormScreen';

export default ({ route }: PartnerCreditCardFormScreenProps): JSX.Element => {
  const { setIsLoading, modalizeData, setModalizeData, setModalData, modalData, authData } =
    React.useContext(AppContext);
  const { storeAuthData } = useChangeAuthData();
  const navigation = useNavigation();
  const params = route.params;

  useFocusEffect(
    React.useCallback(() => {
      if (!params || !params.email || !params.partnerId || !params.price) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'PartnerFormScreen' }],
          });
        }, 500);
      }
    }, []),
  );

  const onConfirm = async (): Promise<void> => {
    if (!params?.partnerId) return;
    setIsLoading(true);
    try {
      const result = await storePartnerCreditCard(formik.values, params?.partnerId);
      setIsLoading(false);
      if (result) {
        const message = `Estimado ${params.name} ${params.surname}, su tarjeta de credito fue guardada exitosamente`;
        setModalizeData({
          content: (
            <SuccessModal
              isSuccess={true}
              title={'Guardado de Tarjeta de Credito Exitoso'}
              mainColor={'#EC1C24'}
              message={message}
              buttonText={authData ? 'Volver al Carnet' : 'Continuar con Pago de Primer Cuota'}
              onConfirm={async () => {
                setModalizeData(null);
                if (authData) {
                  const partnerWithCreditCardInfo = await getPartner(authData.id);
                  if (partnerWithCreditCardInfo) {
                    await storeAuthData(partnerWithCreditCardInfo);
                    navigation.navigate('HomeScreen');
                  }
                } else {
                  navigation.navigate('PaymentScreen', {
                    partnerId: params.partnerId,
                    email: params.email,
                    price: params.price,
                    name: params.name,
                    surname: params.surname,
                    identificationNumber: params.identificationNumber,
                    identificationType: params.identificationType,
                  });
                }
              }}
            />
          ),
          closeOnOverlayTap: false,
        });
      } else {
        setIsLoading(false);
        const message = 'No se pudo hacer su guardar su tarjeta de credito, intente de vuelta';
        setModalizeData({
          content: (
            <SuccessModal
              isSuccess={true}
              title={'Guardado de Tarjeta de Credito Fallido'}
              mainColor={'#EC1C24'}
              message={message}
              buttonText={'Volver'}
              onConfirm={() => {
                setModalizeData(null);
                formik.setSubmitting(false);
              }}
            />
          ),
          closeOnOverlayTap: false,
        });
      }
    } catch (error) {
      formik.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const formik = useFormik<PartnerCreditCardData>({
    initialValues: {
      creditCardBrand: '',
      creditCardNumber: '',
      creditCardExpirationDate: '',
      creditCardCardHolderName: '',
    },
    onSubmit: () => {
      setModalData({
        partnerCreditCardData: formik.values,
        cashPaymentForm: false,
        onContinueSubmit: () => onConfirm(),
        onCancel: () => {
          formik.setSubmitting(false);
          setModalData(null);
        },
      });
    },
    validationSchema: partnerCreditCardFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const disabledConfirm = (): boolean => {
    if (formik.isSubmitting) return true;
    return false;
  };

  const expirationDatePattern = (value: string): string => {
    return value
      .replace(
        /[^0-9]/g,
        '', // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1', // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0', // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        '$1/$2', // To handle 113 > 11/3
      );
  };
  console.log(formik.values);
  return (
    <>
      <ScrollView style={styles.container} scrollEnabled={!modalizeData && !modalData}>
        <View style={{ backgroundColor: '#2A2A2A' }}>
          <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
        </View>
        <View style={styles.arrowContainerSmall}>
          <Icon
            name="arrow-back-outline"
            type="ionicon"
            color="#EC1C24"
            containerStyle={styles.arrowIconSmall}
            onPress={() => navigation.goBack()}
          />
          <View>
            <Text style={styles.labelTextTitle}>
              Complete el siguiente formulario para registrar la tarjeta de credito que será usada en el debito
              automático
            </Text>
          </View>
        </View>
        <View style={styles.formContainer}>
          <Text style={styles.labelTextInput}>
            Tarjeta <Text style={styles.requiredField}>*</Text>
          </Text>
          <Picker
            selectedValue={formik.values.creditCardBrand}
            onValueChange={(value) => {
              formik.setFieldValue('creditCardBrand', value);
            }}
            style={styles.inputSelectContainer}
          >
            {!formik.values.creditCardBrand && <Picker.Item label={'Seleccioná tipo de tarjeta'} value={-1} />}
            <Picker.Item label={'VISA'} value={'VISA'} />
            <Picker.Item label={'MASTERCARD'} value={'MASTERCARD'} />
            <Picker.Item label={'CABAL'} value={'CABAL'} />
            <Picker.Item label={'AMEX'} value={'AMEX'} />
          </Picker>
          {formik.errors.creditCardBrand && formik.errors.creditCardBrand.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.creditCardBrand}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Número de Tarjeta <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="numeric"
            style={styles.textInputField}
            onChangeText={(value: string) =>
              formik.setFieldValue(
                'creditCardNumber',
                value
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(.{4})/g, '$1 ')
                  .trim(),
              )
            }
            maxLength={19}
            value={formik.values.creditCardNumber}
            placeholder={'Ingresá tu número de tarjeta'}
          ></TextInput>
          {formik.errors.creditCardNumber && formik.errors.creditCardNumber.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.creditCardNumber}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Mes y Año de Vto <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="numeric"
            placeholder="10/24"
            maxLength={5}
            style={styles.textInputField}
            onChangeText={(value: string) =>
              formik.setFieldValue('creditCardExpirationDate', expirationDatePattern(value))
            }
            value={formik.values.creditCardExpirationDate}
          ></TextInput>
          {formik.errors.creditCardExpirationDate && formik.errors.creditCardExpirationDate.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.creditCardExpirationDate}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Titular de Tarjeta (justo como aparece en la tarjeta) <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            maxLength={26}
            style={styles.textInputField}
            onChangeText={(value) => formik.setFieldValue('creditCardCardHolderName', value.toUpperCase())}
            placeholder={'Ingresá tu Nombre'}
            value={formik.values.creditCardCardHolderName}
          ></TextInput>
          {formik.errors.creditCardCardHolderName && formik.errors.creditCardCardHolderName.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.creditCardCardHolderName}</Text>
          )}
          <Button
            containerStyle={styles.confirmPaymentBtnContainer}
            buttonStyle={styles.submitButton}
            titleStyle={styles.submitTitle}
            title="Guardar Tarjeta"
            disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
            onPress={(): void => {
              Keyboard.dismiss();
              formik.handleSubmit();
            }}
            disabled={disabledConfirm()}
          />
        </View>
        <Footer />
      </ScrollView>
    </>
  );
};
