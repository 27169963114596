import React from 'react';
import { Platform, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import moment from 'moment-timezone';
import FormFD from 'components/FormFD';
import { getHash, getConfig } from 'services/firstDataService';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/screens/paymentFDScreen';
import { WEB_URL } from '@env';
import ResultPayment from 'types/models/ResultPayment';
import FirstDataConfig from 'types/models/FirstDataConfig';
import PaymentScreenProps from 'types/screens/PaymentFDScreenProps';

export default ({ route }: PaymentScreenProps): JSX.Element => {
  const navigation = useNavigation();
  const params = route.params;
  const { setIsLoading } = React.useContext(AppContext);
  const [paymentData, setPaymentData] = React.useState<any>();
  const [firstDataConfig, setFirstDataConfig] = React.useState<FirstDataConfig | null>(null);
  const [hash, setHash] = React.useState<string | null>(null);
  const [resultPayment, setResultPayment] = React.useState<ResultPayment | null>(null);

  const setFormData = async () => {
    const config = await getConfig();
    if (config) {
      setFirstDataConfig(config);
    }
    if (!config || !params) {
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'PaymentScreen' }],
        });
      }, 500);
      return;
    }
    const date = moment();
    const dateTime = date.tz(config.timeZone).format('YYYY:MM:DD-HH:mm:ss');
    const paymentDataFD = {
      dateTime,
      chargeTotal: Number(params.price).toFixed(2),
    };
    const hash = await getHash(paymentDataFD);
    if (hash) {
      setHash(hash);
      setPaymentData({
        dateTime,
        hash,
        chargeTotal: paymentDataFD.chargeTotal,
      });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setFormData();
      return () => {
        setResultPayment(null);
      };
    }, []),
  );

  React.useEffect(() => {
    if (resultPayment) {
      if (resultPayment.success) {
        navigation.navigate('SuccessPaymentScreen', {
          partnerId: params.partnerId,
        });
      } else {
        navigation.navigate('FailurePaymentScreen');
      }
    }
  }, [resultPayment]);

  if (!firstDataConfig || !hash || !paymentData || !params.partnerId) {
    return <></>;
  }

  return (
    <View style={styles.container}>
      <FormFD
        webHost={Platform.OS === 'web' ? `${window.location.origin}/` : WEB_URL}
        partnerId={params.partnerId}
        timeZone={firstDataConfig.timeZone}
        fdStore={firstDataConfig.fdStore}
        currency={firstDataConfig.currency}
        dateTime={paymentData.dateTime}
        hash={paymentData.hash}
        chargeTotal={paymentData.chargeTotal}
        setIsLoading={setIsLoading}
        setResultPayment={setResultPayment}
        recurringPaymentId={params.recurringPaymentId}
      />
    </View>
  );
};
