import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { View, Image } from 'react-native';
import { Text, Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import Footer from 'components/Footer';
import { styles } from 'styles/screens/successPaymentScreen';
import SuccessPaymentScreenProps from 'types/screens/SuccessPaymentScreenProps';
import AppContext from 'contexts/AppContext';
import useChangeAuthData from 'hooks/useChangeAuthData';
import { getPartner } from 'services/partnerService';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';

export default ({ route }: SuccessPaymentScreenProps): JSX.Element => {
  const navigation = useNavigation();
  const { storeAuthData } = useChangeAuthData();
  const params = route.params;
  const { authData } = React.useContext(AppContext);

  React.useEffect(() => {
    console.clear();
    console.log('route?.params', route?.params);
  }, []);

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      <View style={styles.successContent}>
        <View
          style={{
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            marginBottom: 30,
            borderRadius: 2,
            padding: 30,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 8,
            },
            shadowOpacity: 0.46,
            shadowRadius: 11.14,
            elevation: 17,
          }}
        >
          <View>
            <AntDesign name="checkcircle" size={60} color={'#EC1C24'} />
          </View>
          <Text style={styles.successPaymentText}>Transaccion Exitosa</Text>
        </View>
        <Button
          containerStyle={styles.confirmPaymentBtnContainer}
          buttonStyle={styles.goToLoginBtnContainer}
          titleStyle={styles.goToLoginTitle}
          title={'Ver tu carnet de Socio'}
          onPress={async () => {
            if (params && params.partnerId) {
              const result = await getPartner(params.partnerId);
              if (result) {
                await storeAuthData(result);
              }
              if (authData?.recurringPayments) {
                navigation.navigate('HomeScreen');
              }
            }
          }}
        />
      </View>
      <Footer />
    </View>
  );
};
