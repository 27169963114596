import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
  },
  presentationContainer: {
    backgroundColor: '#2A2A2A',
  },
  presentationTextContainer: {
    alignSelf: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  presentationTitle: {
    marginTop: 10,
    fontSize: 28,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  presentationSubTitle: {
    marginTop: 15,
    fontSize: 24,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  foreignClientText: {
    marginTop: 15,
    fontSize: 24,
    fontWeight: '600',
    color: '#FFFFFF',
    textDecorationLine: 'underline',
  },
  administrativeText: {
    textDecorationLine: 'underline',
  },
  alreadyPartnerText: {
    fontSize: 24,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  formContainer: {
    padding: 20,
  },
  loginSectionContainer: {
    marginTop: 10,
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'center',
  },
  labelTextTitle: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: '400',
    color: '#2A2A2A',
  },
  labelTextInput: {
    marginTop: 5,
    fontSize: 18,
    fontWeight: '400',
    color: '#2A2A2A',
  },
  labelErrorInput: {
    marginTop: 5,
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#FF0000',
  },
  textInputField: {
    marginTop: 10,
    backgroundColor: '#CECDBF',
    height: 60,
    fontSize: 18,
    borderRadius: 5,
    padding: 10,
  },
  uploadImageButton: {
    marginTop: 20,
    marginBottom: 20,
    width: 350,
    height: 50,
    borderRadius: 12,
    alignSelf: 'center',
  },
  uploadImageTitle: {
    fontSize: 18,
  },
  attachmentButton: {
    marginTop: 30,
    marginBottom: 30,
    width: 340,
    height: 60,
    borderRadius: 10,
    justifyContent: 'space-between',
    backgroundColor: '#CECDBF',
  },
  confirmPaymentBtnContainer: {
    alignSelf: 'center',
    bottom: 0,
    marginTop: 30,
    marginBottom: 15,
  },
  submitButton: {
    width: 250,
    height: 80,
    borderRadius: 35,
    alignSelf: 'center',
    backgroundColor: '#EC1C24',
  },
  submitButtonDisabled: {
    backgroundColor: '#c4c4c4',
  },
  attachmentButtonTitle: {
    color: '#2A2A2A',
    fontSize: 18,
    fontWeight: '500',
    justifyContent: 'flex-end',
  },
  submitTitle: {
    color: '#FFF',
    fontWeight: '400',
    fontSize: 24,
    justifyContent: 'flex-end',
  },
  errorText: {
    color: '#EC1C24',
    fontSize: 16,
  },
  inputSelectContainer: {
    height: 60,
    borderRadius: 10,
    fontSize: 18,
    marginTop: 8,
    backgroundColor: '#CECDBF',
  },
  discapacityDocumentTextContainer: {
    marginTop: 8,
  },
  discapacityDocumentText: {
    color: '#2A2A2A',
    fontSize: 16,
    fontWeight: 'bold',
  },
  requiredField: {
    color: '#EC1C24',
    fontSize: 20,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  separatorLine: {
    borderBottomColor: '#CECDBF',
    borderBottomWidth: 3,
    marginTop: 8,
  },
  directionExtrasContainer: {
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    width: '100%',
  },
  extraDirectionField: {
    flexDirection: 'column',
    width: '30%',
  },
  switchContainer: {
    marginTop: 10,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  switch: {
    marginLeft: 5,
    marginTop: 5,
  },
  informationLinksContainer: {
    marginTop: 5,
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 15,
  },
  informationLinksContainerForFooter: {
    marginTop: 5,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    textAlign: 'flex-start',
    marginLeft: 30,
    marginBottom: 15,
  },
  informationLinkText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  disabledFormMessageContainer: {
    padding: 20,
    textAlign: 'center',
    marginBottom: 15,
  },
  disabledFormMessageTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#2A2A2A',
  },
  disableFormMessage: {
    marginTop: 15,
    fontSize: 24,
    fontWeight: '400',
    color: '#2A2A2A',
  },
});
