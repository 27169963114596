import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppContext from 'contexts/AppContext';
import constantsStorage from 'constants/Storage';
import { login } from 'services/authService';
import { getBranchById, getTicketOfficeById } from 'services/userService';
import type { LoginData } from 'types';
import type AuthData from 'types/models/AuthData';
import type useLogin from 'types/hooks/useLogin';
import type { UserLocationState } from 'types/hooks/useLogin';

export default (): useLogin => {
  const { setLoggedUser, setCashPayment, loggedUser } = React.useContext(AppContext);

  const loginUser = async (data: LoginData): Promise<void> => {
    try {
      const lastAuth = await AsyncStorage.getItem(constantsStorage.loggedUser);
      let auth: AuthData | null = null;

      if (!lastAuth) {
        auth = await login(data);
      }

      const newAuth = auth ?? JSON.parse(lastAuth as string);
      await AsyncStorage.setItem(constantsStorage.loggedUser, JSON.stringify(newAuth));
      setCashPayment(true);
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  };

  const authUser = async (initialValues: any): Promise<AuthData | null> => {
    const lastAuth = await AsyncStorage.getItem(constantsStorage.loggedUser);

    if (!lastAuth) return null;

    const obj = JSON.parse(lastAuth);
    const auth = {
      token: obj.token,
      user: {
        ...obj.user,
        branchId: initialValues.branch,
        ticketOfficeId: initialValues.ticketOffice,
      },
    };

    await AsyncStorage.setItem(constantsStorage.loggedUser, JSON.stringify(auth));
    return auth;
  };

  const userLocationState = async (): Promise<UserLocationState> => {
    const state = { branchId: loggedUser?.user.branchId, ticketOfficeId: loggedUser?.user.ticketOfficeId };
    const newState: UserLocationState = { branch: null, ticketOffice: null };

    if (state.branchId && state.ticketOfficeId) {
      newState.branch = await getBranchById(state.branchId);
      newState.ticketOffice = await getTicketOfficeById(state.ticketOfficeId);
    }

    return newState;
  };

  const logoutUser = async (): Promise<{ isLoggedOut: boolean }> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.loggedUser);
      await AsyncStorage.removeItem(constantsStorage.cashPayment);
      setLoggedUser(null);
      setCashPayment(false);
      return { isLoggedOut: true };
    } catch (error) {
      console.error(error);
      return { isLoggedOut: false };
    }
  };

  return { login: loginUser, logout: logoutUser, authUser, userLocationState };
};
