import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export const parseDateTime = (date: Date): string => {
  return moment(date).format('DD [de] MMMM HH:mm');
};

export const parseDateTimeLumen = (date: Date): string => {
  return moment.utc(date).format('yyyy-MM-DD');
};

export const parseDateToFormik = (date: Date): string => {
  return moment(date).format('yyyy-MM-DD');
};
