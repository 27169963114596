import React from 'react';
import { View, Image, TextInput } from 'react-native';
import { Text, Button, Icon } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import SuccessModal from 'components/SuccessModal';
import Footer from 'components/Footer';
import { getExistingPartnerByEmail, sendRecoveryPasswordEmail } from 'services/partnerService';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import { styles } from 'styles/screens/passwordRecoveryWithEmailScreen';
import App from 'constants/App';
import AppContext from 'contexts/AppContext';
import Partner from 'types/models/Partner';

export default (): JSX.Element => {
  const navigation = useNavigation();
  const { setIsLoading, setModalizeData } = React.useContext(AppContext);
  const [email, setEmail] = React.useState<string>('');
  const [partnerForRecoveryPassword, setPartnerForRecoveryPassword] = React.useState<Partner | null>(null);
  const [errorNotExistingPartner, setErrorNotExistingPartner] = React.useState<string | null>(null);

  const buttonDisabled = (): boolean => {
    if (!email) return true;
    if (errorNotExistingPartner) return true;
    if (!partnerForRecoveryPassword) return true;
    return false;
  };

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      <View style={styles.arrowContainerSmall}>
        <Icon
          name="arrow-back-outline"
          type="ionicon"
          color="#EC1C24"
          tvParallaxProperties={null}
          containerStyle={styles.arrowIconSmall}
          onPress={() => navigation.goBack()}
        />
      </View>
      <Text style={styles.title}>Ingreso de Email para recuperación de contraseña</Text>
      <View style={styles.contentContainer}>
        <TextInput
          keyboardType="email-address"
          style={styles.textInputField}
          onChangeText={setEmail}
          placeholder={'Ingresá tu dirección de correo electrónico'}
          value={email}
          onBlur={async () => {
            if (email.match(App.regexEmail)) {
              try {
                setIsLoading(true);
                const result = await getExistingPartnerByEmail(email);
                if (result) {
                  setPartnerForRecoveryPassword(result);
                  setErrorNotExistingPartner(null);
                  setIsLoading(false);
                }
              } catch (error: any) {
                setIsLoading(false);
                console.log(error);
                setErrorNotExistingPartner(`${error}`);
              }
            }
          }}
        ></TextInput>
        {errorNotExistingPartner && (
          <>
            <Text style={styles.errorText}>{errorNotExistingPartner}</Text>
          </>
        )}
        <Button
          title={'Enviar Email'}
          containerStyle={styles.passwordRecoveryButtonContainer}
          buttonStyle={styles.passwordRecoveryButton}
          titleStyle={styles.passwordRecoveryTitle}
          disabledStyle={[styles.passwordRecoveryButton, styles.passwordRecoveryDisabled]}
          disabled={buttonDisabled()}
          onPress={async () => {
            if (email && partnerForRecoveryPassword) {
              setIsLoading(true);
              const sendEmailforPasswordRecovery = await sendRecoveryPasswordEmail(
                email,
                partnerForRecoveryPassword?.name,
                partnerForRecoveryPassword?.partnerNumber,
                partnerForRecoveryPassword?.id,
              );
              if (sendEmailforPasswordRecovery) {
                setIsLoading(false);
                const message = `Email enviado exitosamente a la dirección de correo: ${email}, revise su casilla de correo para continuar con la recuperación de su contraseña`;
                setModalizeData({
                  content: (
                    <SuccessModal
                      isSuccess={true}
                      title={'Email de Recuperación enviado exitosamente'}
                      mainColor={'#EC1C24'}
                      message={message}
                      buttonText={'Volver'}
                      onConfirm={() => {
                        setModalizeData(null);
                        navigation.goBack();
                      }}
                    />
                  ),
                  closeOnOverlayTap: false,
                });
              } else {
                setIsLoading(false);
                const message = `No se pudo enviar el email a la dirección: ${email}, intente de vuelta`;
                setModalizeData({
                  content: (
                    <SuccessModal
                      isSuccess={true}
                      title={'Error en envio de Email de Recuperación'}
                      mainColor={'#EC1C24'}
                      message={message}
                      buttonText={'Volver'}
                      onConfirm={() => {
                        setModalizeData(null);
                      }}
                    />
                  ),
                  closeOnOverlayTap: false,
                });
              }
            }
          }}
        />
      </View>
      <Footer />
    </View>
  );
};
