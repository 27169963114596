import React from 'react';
import { Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import type FormButtonProps from 'types/components/FormButtonProps';

export default ({ onPress, label, disabled }: FormButtonProps): JSX.Element => {
	return (
		<TouchableOpacity
			onPress={onPress}
			disabled={disabled}
			style={{
				width: '100%',
				backgroundColor: '#EC1C24',
				height: 60,
				borderRadius: 10,
				display: 'flex',
				alignItems: 'center',
				marginTop: 38,
				justifyContent: 'center',
				shadowColor: '#ED5156',
				shadowOffset: { width: 0, height: 7 },
				shadowOpacity: 0.41,
				shadowRadius: 9.11,
				elevation: 14
			}}
		>
			<Text style={{
				color: '#fff',
				fontWeight: '800',
				fontSize: 16,
				textTransform: 'capitalize'
			}}
			>
				{label}
			</Text>
		</TouchableOpacity>
	)
}