import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#EC1C24',
  },
  containerPartnerCard: {
    marginTop: 75,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  goBackContainerSmall: {
    alignSelf: 'flex-end',
    flex: 1,
  },
  headerSmall: {
    flexDirection: 'column',
    padding: 10,
    top: 0,
    marginTop: 10,
  },
  textContainer: {
    padding: 10,
    color: '#FFFFFF',
  },
  profileTextTitle: {
    fontSize: 20,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  profileTextDescription: {
    fontSize: 20,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  qrImageContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  qrImage: {
    position: 'absolute',
    alignSelf: 'center',
    marginTop: 20,
    height: 160,
    width: 160,
    borderRadius: 15,
  },
  image: {
    alignSelf: 'center',
    marginTop: 20,
    height: 120,
    width: 120,
    borderRadius: 15,
  },
  profileImageContainer: {
    flexDirection: 'column',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
  },
  profileTextsContainer: {
    flexDirection: 'column',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
  },
  recurringPaymentInfoContainer: {
    padding: 20,
    marginBottom: 15,
  },
  recurringPaymentPaymentButtonContainer: {
    alignSelf: 'center',
    bottom: 0,
  },
  recurringPaymentPaymentButton: {
    width: 250,
    height: 80,
    borderRadius: 35,
    alignSelf: 'center',
    backgroundColor: '#2A2A2A',
  },
  recurringPaymentPaymentTitle: {
    color: '#FFF',
    fontWeight: '400',
    fontSize: 24,
    justifyContent: 'flex-end',
  },
  automaticDebitContainer: {
    padding: 20,
  },
  creditCardTextContainer: {
    padding: 10,
    textAlign: 'center',
    color: '#FFFFFF',
  },
});
