import React from 'react';
import { View, Text, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/core';
import { Button } from 'react-native-elements';
import Footer from 'components/Footer';
import { styles } from 'styles/screens/paymentScreen';
//import { getPreference } from 'services/mercado-pago-service';
import PaymentScreenProps from 'types/screens/PaymentScreenProps';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';

export default ({ route }: PaymentScreenProps): JSX.Element => {
  const navigation = useNavigation();
  const params = route.params;

  React.useEffect(() => {
    console.log('route.params', route.params);
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      if (!params || !params.email || !params.partnerId || !params.price) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'LoginScreen' }],
          });
        }, 500);
      }
    }, []),
  );

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      <View style={styles.paymentContent}>
        <View style={styles.infoRegistrationContainer}>
          <Text style={styles.infoRegistrationText}>
            Ya casi esta completa tu alta como socio de independiente, para finalizar podes hacer tap en el botón debajo
            para abonar.
          </Text>
          <View style={styles.infoRegistrationPartnerDataContainer}>
            <Text style={styles.infoRegistrationText}>{`${params.name} ${params.surname}`}</Text>
            <Text style={styles.infoRegistrationText}>{`Tipo de Identificación: ${params.identificationType}`}</Text>
            <Text
              style={styles.infoRegistrationText}
            >{`Número de Identificación: ${params.identificationNumber}`}</Text>
            <Text style={styles.infoRegistrationText}>{`Monto a Cobrar: $${params.price}`}</Text>
          </View>
        </View>
        <Button
          title="Pagar"
          containerStyle={styles.confirmPaymentBtnContainer}
          buttonStyle={[styles.confirmPaymentBtn]}
          titleStyle={styles.confirmPaymentTitle}
          onPress={
            /*async () => {
            if (!params || !params.email || !params.partnerId || !params.price) return;
            const preference = await getPreference(params.email, params.partnerId, {
              title: 'Socios de Cai',
              description: 'Pago de mensualidad',
              quantity: 1,
              currency_id: 'ARS',
              unit_price: parseFloat(params.price),
            });

            if (Platform.OS === 'web') {
              window.open(preference.init_point, '_self');
            }
          }*/ () => {
              navigation.navigate('PartnerPaymentCardFormScreen', {
                partnerId: params.partnerId,
                email: params.email,
                price: params.price,
              });
            }
          }
        ></Button>
      </View>
      <Footer />
    </View>
  );
};
