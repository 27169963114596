import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  legendText: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 18,
    color: '#89909B',
    opacity: 0.8,
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    padding: 15,
    color: '#89909b',
    fontSize: 18,
    textAlign: 'center',
  },
  onConfirmContainer: {
    alignSelf: 'center',
  },
  onConfirmText: {
    fontSize: 18,
  },
});
