import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  containerForm: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  containerLandscape: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#0F6657',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    marginBottom: 35,
    alignSelf: 'center',
    width: 160,
    height: 160,
    resizeMode: 'stretch',
  },
  titleLandscape: {
    marginBottom: 35,
    alignSelf: 'center',
    width: 130,
    height: 130,
    resizeMode: 'stretch',
    marginRight: 45,
  },
  goBackContainerSmall: {
    alignSelf: 'flex-start',
    flex: 1,
  },
  arrowIconSmall: {
    alignSelf: 'flex-start',
    flex: 1,
  },
  arrowContainerSmall: {
    flexDirection: 'column',
    padding: 10,
    top: 0,
    marginTop: 10,
  },
  recoverPasswordText: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: '400',
    color: '#EC1C24',
  },
});
