import axios from 'axios';
import axiosInstance from './axios';
import AuthData from 'types/models/AuthData';
import User from 'types/models/User';
import { BASE_URL } from '@env';

export const login = async (data: { email: string, password: string }): Promise<AuthData> => {
  const { data: response } = await axiosInstance({
    method: 'POST',
    url: '/login',
    data
  });

  return parseAuthData(response);
}

export const loginEmail = async (email: string, password: string, expoPushToken?: string): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/moderator',
      data: {
        email,
        password,
        expoPushToken,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAuthData = async (token: string): Promise<User | null> => {
  try {
    const { data } = await axios.get(`${BASE_URL}auth`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseAuthData = (data: any): AuthData => {
  return {
    token: data.token,
    user: parseUser(data.user),
  };
};

const parseUser = (user: any): User => {
  return {
    id: user.id,
    email: user.email,
    roleId: user.role_id,
    name: user.name,
    lastName: user.lastname,
  };
};
