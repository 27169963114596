import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  paymentContent: {
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    justifyContent: 'center',
  },
  confirmPaymentBtnContainer: {
    marginTop: 35,
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
    bottom: 0,
    borderRadius: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  },
  confirmPaymentBtn: {
    height: 90,
    backgroundColor: '#EC1C24',
  },
  confirmPaymentTitle: {
    color: '#FFFFFF',
    fontWeight: '400',
    fontSize: 22,
    lineHeight: 17,
  },
  infoRegistrationContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  infoRegistrationPartnerDataContainer: {
    marginTop: 15,
  },
  infoRegistrationText: {
    color: '#2A2A2A',
    fontSize: 18,
  },
});
