import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { imageValidation, enabledValidation, fileValidation } from 'utils/yup';
import IdentificationType from 'types/enums/IdentificationType';

const today = new Date();
today.setHours(0, 0, 0, 0);
const localPhoneNumberRegex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
const foreignPhoneNumberRegex = /^\+[1-9]{1}[0-9]{1,14}$/;
const noWhiteSpacesRegex = /^\S*$/;

export default (cashPaymentActivated: boolean | null, livesAbroad: boolean): AnyObjectSchema => {
  return yup.object().shape(
    {
      name: yup.string().required('El nombre es requerido'),
      surname: yup.string().required('El Apellido es requerido'),
      identificationType: yup.string().required('El Tipo de Identificación es requerido'),
      identificationNumber: livesAbroad
        ? yup.mixed().when('identificationType', {
            is: IdentificationType.OD,
            then: yup
              .string()
              .required('El número de documento es requerido')
              .matches(noWhiteSpacesRegex, 'No puede haber espacios en el número de documento'),
          })
        : yup.mixed().when('identificationType', {
            is: IdentificationType.PA,
            then: yup
              .string()
              .required('El número de pasaporte es requerido')
              .min(8, 'El número de pasaporte debe tener un mínimo de 8 caracteres')
              .max(9, 'El número de pasaporte debe tener un máximo de 9 caracteres')
              .matches(/^[^.]*$/, 'El número de pasaporte no puede tener puntos'),
            otherwise: yup
              .string()
              .required('El número de identificación es requerido')
              .min(7, 'El número de documento debe tener un mínimo de 7 dígitos')
              .max(8, 'El número de documento debe tener un máximo de 8 dígitos')
              .matches(/^\d{7,8}$/, 'No puede haber espacios, letras ni caracteres especiales, solo números'),
          }),
      uniqueIdentificationCode: yup.string(),
      sex: yup.string().required('El Sexo es requerido'),
      dateOfBirth: yup
        .date()
        .required('La Fecha de nacimiento de requerida')
        .max(today, 'La fecha no puede ser superior al día de hoy'),
      nationality: yup.string().required('La nacionalidad es requerida'),
      telephoneNumber: yup
        .string()
        .matches(
          livesAbroad ? foreignPhoneNumberRegex : localPhoneNumberRegex,
          `El número de teléfono fijo de ser un número válido ${
            livesAbroad ? 'y tener prefijo del pais (comenzando con +) y tu ciudad' : ''
          }`,
        ),
      mobilePhoneNumber: yup
        .string()
        .required('El número de teléfono celular es requerido')
        .matches(
          livesAbroad ? foreignPhoneNumberRegex : localPhoneNumberRegex,
          `El número de teléfono celular debe ser un número válido ${
            livesAbroad ? 'y tener prefijo del pais y tu ciudad' : ''
          }`,
        ),
      email: yup.string().required('El email es requerido').email('Debe ingresar un email válido'),
      photo: imageValidation('foto', true),
      identificationFront: imageValidation('frente del DNI', true),
      identificationBack: imageValidation('dorso del DNI', true),
      country: yup.string().required('El país es requerido'),
      postalCode: yup
        .number()
        .typeError('El código postal debe ser un número')
        .required('El cdigo postal es requerido'),
      locality: yup.string().required('La localidad es requerida'),
      province: yup.string().required('La provincia es requerida'),
      street: yup.string().required('El nombre de la calle es requerido'),
      streetNumber: yup.string().required('El número de la calle es requerido'),
      tower: yup.string(),
      floor: yup.string(),
      department: yup.string(),
      livesFarAway: enabledValidation,
      hasDiscapacity: enabledValidation,
      autoDebit: enabledValidation,
      discapacityFile: yup.mixed().when('hasDiscapacity', {
        is: true,
        then: fileValidation(),
      }),
      subsidiary: yup.string(),
      cardDeliveryOption: livesAbroad
        ? yup.string()
        : yup.string().required('Debe seleccionar una opción para la entrega de su carnet de socio'),
      isCash: cashPaymentActivated
        ? yup.mixed().when('isScholarshipMember', {
            is: false,
            then: yup.boolean().oneOf([true], 'Debe marcar si es pago en efectivo o '),
            otherwise: yup.boolean().oneOf([false]),
          })
        : yup.boolean(),
      isScholarshipMember: cashPaymentActivated
        ? yup.mixed().when('isCash', {
            is: false,
            then: yup.boolean().oneOf([true], 'si es becario'),
            otherwise: yup.boolean().oneOf([false]),
          })
        : yup.boolean(),
      ticketNumber: cashPaymentActivated ? yup.string().required('El número de ticket es requerido') : yup.string(),
    },
    [['isCash', 'isScholarshipMember']],
  );
};
