import React from 'react';
import { View, Text } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { AntDesign } from '@expo/vector-icons';
import SuccessModalProps from '../types/components/SuccessModalProps';
import { styles } from '../styles/components/successModal';

export default ({ isSuccess, title, message, buttonText, onConfirm, mainColor }: SuccessModalProps): JSX.Element => {
  return (
    <>
      <Card.Title style={styles.legendText}>{title}</Card.Title>
      <Card.Divider />
      <View style={styles.iconsContainer}>
        {isSuccess ? (
          <AntDesign name="checkcircle" size={48} color={mainColor} />
        ) : (
          <AntDesign name="exclamationcircle" size={48} color={mainColor} />
        )}
      </View>
      <View>
        <Text style={styles.message}>{message}</Text>
      </View>
      <Card.Divider />
      <Button
        title={buttonText}
        titleStyle={[styles.onConfirmText, { color: mainColor }]}
        containerStyle={styles.onConfirmContainer}
        type="clear"
        onPress={() => onConfirm()}
      ></Button>
    </>
  );
};
