import React from 'react';
import { Text, View } from 'react-native';
import { Button, Card } from 'react-native-elements';
import ForeignPartnerAlertProps from 'types/components/ForeignPartnerAlertProps';
import { styles } from 'styles/components/foreignPartnerAlert';
import { AntDesign } from '@expo/vector-icons';

export default ({ onCancel }: ForeignPartnerAlertProps): JSX.Element => {
  return (
    <>
      <Card.Title style={styles.legendText}>Aviso</Card.Title>
      <Card.Divider style={[styles.titleDivider]} />
      <View style={styles.iconsContainer}>
        <AntDesign name="exclamationcircle" size={48} color={'#f00'} />
      </View>
      <View>
        <Text style={styles.message}>
          Consultar con{' '}
          <Text dataDetectorType={'email'} style={{ color: '#EC1C24' }}>
            socios@clubaindependiente.com.ar
          </Text>{' '}
          para el envío del carnet.
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          title={'Entendido'}
          type="clear"
          buttonStyle={[styles.continueButton]}
          titleStyle={styles.continueText}
          onPress={() => {
            onCancel();
          }}
        ></Button>
      </View>
    </>
  );
};
