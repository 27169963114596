import * as React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import AppContext from 'contexts/AppContext';
import useChangeAuthData from 'types/hooks/useChangeAuthData';
import Partner from 'types/models/Partner';

export default (): useChangeAuthData => {
  const { setAuthData } = React.useContext(AppContext);
  const storeAuthData = async (authData: Partner): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.authData, JSON.stringify(authData));
      setAuthData(authData);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAuthData = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.authData);
      setAuthData(null);
    } catch (error) {
      console.log(error);
    }
  };
  return { storeAuthData, deleteAuthData };
};
