import React from 'react';
import { Image, Text, View, TouchableOpacity } from 'react-native';
import { styles } from 'styles/components/footer';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import TelegramIcon from 'assets/images/redes-01.png';
import TiktokIcon from 'assets/images/redes-02.png';
import TwitterIcon from 'assets/images/redes-03.png';
import FacebookIcon from 'assets/images/redes-04.png';
import YoutubeIcon from 'assets/images/redes-05.png';
import InstagramIcon from 'assets/images/redes-06.png';
import UnknownIcon from 'assets/images/redes-07.png';
import App from 'constants/App';

export default (): JSX.Element => {
  return (
    <View
      style={{
        backgroundColor: '#2A2A2A',
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <View style={{ flex: 1, left: 0 }}>
        <Image
          style={{ height: 120, width: '80%', justifyContent: 'center' }}
          resizeMode={'contain'}
          source={HeaderImage}
        />
        <View style={styles.informationLinksContainerForFooter}>
          <Text
            onPress={() => {
              window.open(App.faqsUrl, '_blank');
            }}
            style={styles.informationLinkText}
          >
            Preguntas frecuentes
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: '#2A2A2A',
            marginTop: 10,
            alignSelf: 'flex-start',
            marginLeft: 30,
            marginBottom: 30,
          }}
        >
          <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={TelegramIcon} />
          <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={TiktokIcon} />
          <TouchableOpacity
            onPress={() => {
              window.open('https://twitter.com/Independiente', '_blank');
            }}
          >
            <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={TwitterIcon} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              window.open('https://facebook.com/Independiente', '_blank');
            }}
          >
            <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={FacebookIcon} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              window.open('https://youtube.com/channel/UCvjX3jBp_iLCZCpKqlXhHDw', '_blank');
            }}
          >
            <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={YoutubeIcon} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              window.open('https://instagram.com/caindependiente', '_blank');
            }}
          >
            <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={InstagramIcon} />
          </TouchableOpacity>
          <Image style={{ height: 45, width: 45 }} resizeMode={'contain'} source={UnknownIcon} />
        </View>
      </View>
    </View>
  );
};
