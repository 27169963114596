import axiosInstance from './axios';
import DecidirPaymentMethod from 'types/models/DecidirPaymentMethod';

export const storeDecidirPayment = async (decidirTokendata: any): Promise<any | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'decidir/payments',
      data: {
        partnerId: decidirTokendata.partnerId,
        partnerEmail: decidirTokendata.email,
        price: decidirTokendata.price,
        token: decidirTokendata.token,
        bin: decidirTokendata.bin,
        cardNumber: decidirTokendata.cardNumber,
        paymentMethod: decidirTokendata.paymentMethod,
        cardExpirationMonth: decidirTokendata.cardExpirationMonth,
        cardExpirationYear: decidirTokendata.cardExpirationYear,
        cardLastFourDigits: decidirTokendata.cardLastFourDigits,
        cardHolderName: decidirTokendata.cardHolderName,
      },
    });
    return data;
  } catch (error: any) {
    console.log(error);
    return null;
  }
};

export const getDecidirPaymentMethods = async (): Promise<DecidirPaymentMethod[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `decidir/payment-methods`,
    });
    return parseDecidirPaymentMethods(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseDecidirPaymentMethods = (decidirPaymentMethods: any): DecidirPaymentMethod[] => {
  return decidirPaymentMethods.map((decidirPaymentMethod: any) => parseDecidirPaymentMethod(decidirPaymentMethod));
};

const parseDecidirPaymentMethod = (decidirPaymentMethod: any): DecidirPaymentMethod => {
  return {
    id: decidirPaymentMethod.id,
    paymentMethodId: decidirPaymentMethod.payment_method_id,
    brand: decidirPaymentMethod.brand,
  };
};
