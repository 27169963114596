/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance, { axiosMultipart } from './axios';
import type Partner from 'types/models/Partner';
import type PartnerImage from 'types/models/PartnerImage';
import type Subsidiary from 'types/models/Subsidiary';
import type CardDeliveryOption from 'types/models/CardDeliveryOption';
import type PartnerCategory from 'types/models/PartnerCategory';
import RecurringPayment from 'types/models/RecurringPayment';
import PartnerCreditCard from 'types/models/PartnerCreditCard';
import PartnerCreditCardData from 'types/models/PartnerCreditCardData';

interface CheckPartnerExists {
  partnerExists: boolean;
  partner: Partner | undefined;
  price: number;
}

export const storePartner = async (formData: FormData): Promise<Partner | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'POST',
      url: 'partner',
      data: formData,
    });
    return parsePartnerData(data);
  } catch (error: any) {
    console.log(error);
    return null;
  }
};

export const checkExistingPartner = async (identification: string): Promise<CheckPartnerExists> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `check-partner/${identification}`,
    });
    return {
      partnerExists: data.partnerExists,
      partner: data.partner ? parsePartnerData(data.partner) : undefined,
      price: data.price,
    };
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.error);
  }
};

export const checkExistingPartnerEmail = async (email: string): Promise<boolean> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `check-partner-email/${email}`,
    });
    return data !== null;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.error);
  }
};

export const checkExistingPartnerTicket = async (ticket: string): Promise<boolean> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `check-partner-ticket/${ticket}`,
    });
    return data !== null;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.error);
  }
};

export const loginPartner = async (email: string, password: string): Promise<Partner | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login-partner',
      data: {
        email,
        password,
      },
    });
    return parsePartnerData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPartner = async (partnerId: string): Promise<Partner | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `partner/${partnerId}`,
    });
    return parsePartnerData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getSubsidiaries = async (): Promise<Subsidiary[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `subsidiaries`,
    });
    return parseSubsidiaries(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCardDeliveryOptions = async (): Promise<CardDeliveryOption[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'card-delivery-options',
    });
    return parseCardDeliveryOptions(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeUserRecurringPayment = async (
  partnerId: string,
  partnerCategoryPrice: number,
  recurringPaymentId?: string,
): Promise<RecurringPayment | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'recurring-payment',
      data: {
        partnerId,
        partnerCategoryPrice,
        recurringPaymentId,
      },
    });
    return parseRecurringPayment(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPendingRecurringPayment = async (partnerId: string): Promise<RecurringPayment | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `pending-recurring-payment/${partnerId}`,
    });
    return parseRecurringPayment(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getExistingPartnerByEmail = async (email: string): Promise<Partner | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `check-partner-by-email/${email}`,
    });
    return parsePartnerData(data);
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.error);
  }
};

export const sendRecoveryPasswordEmail = async (
  email: string,
  name: string,
  partnerNumber: string,
  partnerId: string,
): Promise<boolean> => {
  try {
    await axiosInstance({
      method: 'post',
      url: 'send-password-recovery-email',
      data: {
        email,
        name,
        partnerId,
        partnerNumber,
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const storeNewPartnerPassword = async (partnerId: string, password: string): Promise<Partner | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'new-partner-password',
      data: {
        password,
        partnerId,
      },
    });
    return parsePartnerData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storePartnerCreditCard = async (
  creditCardData: PartnerCreditCardData,
  partnerId: string,
): Promise<PartnerCreditCard | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'partner-credit-card',
      data: {
        ...creditCardData,
        partnerId,
      },
    });
    return parsePartnerCreditCard(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parsePartnerData = (partner: any): Partner => {
  return {
    id: partner.id,
    partnerNumber: partner.partner_number,
    surname: partner.surname,
    name: partner.name,
    identificationType: partner.identification_type,
    identificationNumber: partner.identification_number,
    dateOfBirth: partner.data_of_birth,
    nationality: partner.nationality,
    telephoneNumber: partner.telephone_number,
    mobilePhoneNumber: partner.mobile_phone_number,
    email: partner.email,
    country: partner.country,
    postalCode: partner.postal_code,
    locality: partner.locality,
    street: partner.street,
    streetNumber: partner.street_number,
    floor: partner.floor,
    department: partner.department,
    subsidiary: partner.subsidiary,
    livesFarAway: partner.lives_far_away === 1,
    autoDebit: partner.auto_debit,
    statusId: partner.status_id,
    livesAbroad: partner.lives_abroad === 1,
    uniqueIdentificationCode: partner.unique_identification_code ?? undefined,
    images: partner.images ? parsePartnerImages(partner.images) : [],
    category: parsePartnerCategory(partner.partner_category),
    isScholarshipMember: partner.is_scholarship_member === 1,
    price: partner.price ? partner.price : 0,
    charge: partner.charge ?? undefined,
    recurringPayments: partner.recurring_payments ? parseRecurringPayments(partner.recurring_payments) : undefined,
    creditCard: partner.credit_card ? parsePartnerCreditCard(partner.credit_card) : undefined,
  };
};

const parseSubsidiaries = (subsidiaries: any): Subsidiary[] => {
  return subsidiaries.map((subsidiary: any) => parseSubsidiary(subsidiary));
};

const parseSubsidiary = (subsidiary: any): Subsidiary => {
  return {
    id: subsidiary.id,
    description: subsidiary.description,
    city: subsidiary.city,
  };
};

const parseCardDeliveryOptions = (cardDeliveryOptions: any): CardDeliveryOption[] => {
  return cardDeliveryOptions.map((cardDeliveryOption: any) => parseCardDeliveryOption(cardDeliveryOption));
};

const parseCardDeliveryOption = (cardDeliveryOption: any): CardDeliveryOption => {
  return {
    id: cardDeliveryOption.id,
    description: cardDeliveryOption.description,
  };
};

const parsePartnerImages = (images: any): PartnerImage[] => {
  return images.map(
    (image: any): PartnerImage => ({
      partnerId: image.pivot.partner_id,
      partnerImageId: image.pivot.partner_image_type_id,
      path: image.path,
    }),
  );
};

const parsePartnerCategory = (category: any): PartnerCategory => {
  return {
    id: category.id,
    description: category.description,
  };
};

const parseRecurringPayments = (recurringPayments: any): RecurringPayment[] => {
  return recurringPayments.map((recurringPayment: any) => parseRecurringPayment(recurringPayment));
};

const parseRecurringPayment = (recurringPayment: any): RecurringPayment => {
  return {
    id: recurringPayment.id,
    feeNumber: recurringPayment.recurring_payment_number,
    feeAmount: recurringPayment.recurring_payment_amount,
  };
};

const parsePartnerCreditCard = (creditCard: any): PartnerCreditCard => {
  return {
    id: creditCard.id,
    creditCardBrand: creditCard.credit_card_brand,
    creditCardNumber: creditCard.credit_card_number,
    creditCardCardHolderName: creditCard.credit_card_cardholder_name,
    creditCardExpirationDate: creditCard.credit_card_expiration_date,
  };
};
