import * as yup from 'yup';

export default yup.object().shape({
  creditCardBrand: yup.string().required('El tipo de tarjeta es requerido'),
  creditCardCardHolderName: yup
    .string()
    .required('El nombre es requerido')
    .max(26, 'Longitud máxima alcanzada')
    .matches(/^[aA-zZ\s]+$/, 'Solamente se permiten letras'),
  creditCardNumber: yup.string().required('El número de la tarjeta es requerido'),
  creditCardExpirationDate: yup.string().required('La fecha de expiración de la tarjeta es requerida'),
});
