import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/core';
import { View, Image } from 'react-native';
import { Text, Button } from 'react-native-elements';
import { styles } from 'styles/screens/failurePaymentScreen';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import Footer from 'components/Footer';
import { getPartner } from 'services/partnerService';
import useChangeAuthData from 'hooks/useChangeAuthData';
import AppContext from 'contexts/AppContext';
import FailurePaymentScreenProps from 'types/screens/FailurePaymentScreenProps';

export default ({ route }: FailurePaymentScreenProps): JSX.Element => {
  const navigation = useNavigation();
  const { storeAuthData } = useChangeAuthData();
  const { authData } = React.useContext(AppContext);
  const params = route.params;

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      <View style={styles.failurePaymentContent}>
        <View
          style={{
            alignItems: 'center',
            backgroundColor: '#fff',
            marginBottom: 30,
            borderRadius: 2,
            padding: 30,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 8,
            },
            shadowOpacity: 0.46,
            shadowRadius: 11.14,
            elevation: 17,
          }}
        >
          <View>
            <AntDesign name="exclamationcircle" size={60} color={'#EC1C24'} />
          </View>
          <Text style={styles.failurePaymentText}>Pago fallido</Text>
        </View>
        <Button
          containerStyle={styles.confirmPaymentBtnContainer}
          buttonStyle={styles.goToLoginBtnContainer}
          titleStyle={styles.failurePaymentTextButton}
          title={'Volver al formulario de registro'}
          onPress={async () => {
            if (authData) {
              const result = await getPartner(authData.id);
              if (result) {
                await storeAuthData(result);
              }
              navigation.navigate('HomeScreen');
            } else {
              navigation.navigate('PartnerFormScreen');
            }
          }}
        />
        <Button
          containerStyle={styles.confirmPaymentBtnContainer}
          buttonStyle={styles.goToLoginBtnContainer}
          titleStyle={styles.failurePaymentTextButton}
          title={'Volver a intentar el pago'}
          onPress={async () => {
            navigation.navigate('PartnerPaymentCardFormScreen', {
              partnerId: params.partnerId,
              email: params.email,
              price: params.price,
              retryPayment: 'true',
            });
          }}
        />
      </View>
      <Footer />
    </View>
  );
};
