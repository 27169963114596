import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  textInputField: {
    marginTop: 10,
    marginBottom: 25,
    backgroundColor: '#CECDBF',
    height: 60,
    fontSize: 18,
    borderRadius: 5,
    padding: 10,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 20,
  },
  contentContainer: {
    padding: 20,
    marginBottom: 20,
  },
  passwordRecoveryButtonContainer: {
    marginTop: 20,
    alignSelf: 'center',
    bottom: 0,
  },
  passwordRecoveryButton: {
    width: 250,
    height: 80,
    borderRadius: 35,
    alignSelf: 'center',
    backgroundColor: '#2A2A2A',
  },
  passwordRecoveryTitle: {
    color: '#FFF',
    fontWeight: '400',
    fontSize: 24,
    justifyContent: 'flex-end',
  },
  passwordRecoveryDisabled: {
    backgroundColor: '#c4c4c4',
  },
  errorText: {
    color: '#EC1C24',
    fontSize: 16,
    marginBottom: 10,
  },
  arrowIconSmall: {
    alignSelf: 'flex-start',
    flex: 1,
  },
  arrowContainerSmall: {
    flexDirection: 'column',
    padding: 10,
    top: 0,
    marginTop: 10,
  },
});
