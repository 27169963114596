import React from 'react';
import { Formik } from 'formik';
import { Image, Text, TextInput, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import AppContext from 'contexts/AppContext';
import { FormStepCarousel, FormSteps } from 'components/Steps';
import FormButton from 'components/FormButton';
import useLogin from 'hooks/useLogin';
import { getBranches, getTicketOffices } from 'services/userService';
import useMediumScreen from 'hooks/useMediumScreen';
import type Branch from 'types/models/Branch';
import type TicketOffice from 'types/models/TicketOffice';
import LoginOperatorProps from 'types/components/LoginOperatorProps';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import { styles } from 'styles/forms/loginForm';
import { styles as partnerFormStyles } from 'styles/screens/partnerFormScreen';

export default ({ isCashPaymentActive }: LoginOperatorProps): JSX.Element => {
  const { loggedUser, setLoggedUser, setCashPayment } = React.useContext(AppContext);
  const [passedSteps, setPassedSteps] = React.useState<number[]>([0]);
  const [currStep, setCurrStep] = React.useState<number>(0);
  const [fetchedBranches, setFetchedBranches] = React.useState<Branch[] | null>([]);
  const [fetchedTicketOffices, setFetchedTicketOffices] = React.useState<TicketOffice[] | null>([]);
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [hideLogin, setHideLogin] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<InitialValues>({
    branch: -1,
    ticketOffice: -1,
  });
  const mediumScreen = useMediumScreen();
  const { login, authUser } = useLogin();
  const isPickersDisabled = Object.values(initialValues).includes(-1);

  React.useEffect(() => {
    (async () => {
      try {
        setFetchedBranches(await getBranches());
        setFetchedTicketOffices(await getTicketOffices());
      } catch (e: any) {
        console.error(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!loggedUser) {
      setHideLogin(false);
      setPassedSteps([0]);
      setCurrStep(0);
    }
  }, [loggedUser]);

  const handleSubmit = (values: any) => {
    login({ email: values.email, password: values.password })
      .then(() => {
        setCurrStep(1);
        setPassedSteps([...passedSteps, 1]);
      })
      .catch((e: any) => {
        setErrorMsg('Correo eletrónico o contraseña incorrecta!');
        console.error(e);
      });
  };

  return !hideLogin && isCashPaymentActive ? (
    <View
      style={{
        position: 'fixed' as 'absolute',
        backgroundColor: '#2A2A2A',
        width: '100%',
        height: '100vh',
        paddingHorizontal: 160,
        zIndex: 999,
        overflow: 'hidden',
      }}
    >
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Image style={{ height: 120, width: '100%', marginBottom: 25 }} resizeMode={'contain'} source={HeaderImage} />
        <Text style={{ color: '#fff', fontSize: 22, fontWeight: '600', textAlign: 'center' }}>Bienvenido!</Text>
        <FormSteps currStep={currStep} passedSteps={passedSteps} />
        <FormStepCarousel step={currStep}>
          <View style={{ width: 450, marginRight: 450 }}>
            <Formik validationSchema={null} initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <>
                  <TextInput
                    placeholder="Correo eletrónico"
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    style={mediumScreen ? styles.inputContainerLarge : styles.inputContainerSmall}
                    placeholderTextColor="#2A2A2A"
                    keyboardType="email-address"
                  />
                  <View style={{ marginVertical: 3 }} />
                  <TextInput
                    placeholder="Contraseña"
                    onChangeText={handleChange('password')}
                    onBlur={handleBlur('password')}
                    value={values.password}
                    style={mediumScreen ? styles.inputContainerLarge : styles.inputContainerSmall}
                    placeholderTextColor="#2A2A2A"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    textContentType="password"
                    keyboardType="default"
                  />
                  {errorMsg && (
                    <Text
                      style={{ color: '#F7584D', fontSize: 16, fontWeight: '800', textAlign: 'center', marginTop: 18 }}
                    >
                      {errorMsg}
                    </Text>
                  )}
                  <FormButton
                    label="Iniciar Sesión"
                    onPress={() => {
                      handleSubmit();
                    }}
                  />
                </>
              )}
            </Formik>
          </View>
          <View style={{ width: 450 }}>
            <Picker
              selectedValue={initialValues.branch}
              onValueChange={(value) => {
                setInitialValues((initial) => ({ ...initial, branch: Number(value) }));
              }}
              style={partnerFormStyles.inputSelectContainer}
            >
              <Picker.Item label={'Seleccioná una sucursal'} value={-1} />
              {fetchedBranches?.map(({ id, name }, index) => (
                <Picker.Item key={index} label={name} value={id} />
              ))}
            </Picker>
            <View style={{ marginVertical: 3 }} />
            <Picker
              selectedValue={initialValues.ticketOffice}
              onValueChange={(value) => {
                setInitialValues((initial) => ({ ...initial, ticketOffice: Number(value) }));
              }}
              style={partnerFormStyles.inputSelectContainer}
            >
              <Picker.Item label={'Seleccioná una boleteria'} value={-1} />
              {fetchedTicketOffices
                ?.filter((fto) => fto.branchId === initialValues.branch)
                .map(({ id, name }, index) => (
                  <Picker.Item key={index} label={name} value={id} />
                ))}
            </Picker>
            <FormButton
              label="Entrar"
              disabled={isPickersDisabled}
              onPress={async () => {
                if (isPickersDisabled) return;
                setHideLogin(true);
                setCashPayment(true);
                setLoggedUser(await authUser(initialValues));
              }}
            />
          </View>
        </FormStepCarousel>
      </View>
    </View>
  ) : (
    <></>
  );
};

type InitialValues = { branch: number | -1; ticketOffice: number | -1 };
