import axiosInstance, { multiCollectionParser } from './axios';
import type Branch from 'types/models/Branch';
import type TicketOffice from 'types/models/TicketOffice';

export const getBranches = async (): Promise<Branch[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `branches`,
    });
    return multiCollectionParser(data, parseBranch);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTicketOffices = async (): Promise<TicketOffice[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `ticket-offices`,
    });
    return multiCollectionParser(data, parseTicketOffice);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getBranchById = async (branchId: number): Promise<Branch | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `branches/${branchId}`,
    });
    return parseBranch(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTicketOfficeById = async (ticketOfficeId: number): Promise<TicketOffice | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `ticket-offices/${ticketOfficeId}`,
    });
    return parseTicketOffice(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseBranch = (branch: any): Branch => {
  return {
    id: branch.id,
    name: branch.name,
    location: branch.location,
  };
};

const parseTicketOffice = (ticketOffice: any): TicketOffice => {
  return {
    id: ticketOffice.id,
    name: ticketOffice.name,
    branchId: ticketOffice.branch_id,
  };
};
