import * as React from 'react';
import { View, Image, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import { styles } from 'styles/screens/loginScreen';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import useOrientationPortrait from 'hooks/useOrientationPortrait';
import useMediumScreen from 'hooks/useMediumScreen';
import LoginForm from 'forms/LoginForm';
import Footer from 'components/Footer';

export default (): JSX.Element => {
  const medium = useMediumScreen();
  const orientationPort = useOrientationPortrait();
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      {navigation.canGoBack() && (
        <View style={styles.arrowContainerSmall}>
          <Icon
            name="arrow-back-outline"
            type="ionicon"
            color="#EC1C24"
            tvParallaxProperties={null}
            containerStyle={styles.arrowIconSmall}
            onPress={() => navigation.navigate('PartnerFormScreen')}
          />
        </View>
      )}
      <View style={medium || orientationPort ? styles.containerForm : styles.containerLandscape}>
        <LoginForm />
        <Text
          onPress={() => {
            navigation.navigate('PasswordRecoveryWithEmailScreen');
          }}
          style={styles.recoverPasswordText}
        >
          Recuperar Contraseña
        </Text>
      </View>
      <Footer />
    </View>
  );
};
