import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  legendText: {
    marginTop: 10,
    fontSize: 18,
    lineHeight: 23,
    color: '#000',
    opacity: 0.8,
  },
  titleDivider: {
    borderBottomWidth: 1,
    borderBottomColor: '#f00',
  },
  message: {
    padding: 15,
    color: '#89909b',
    fontSize: 18,
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'center',
  },
  continueButton: {
    backgroundColor: '#f00',
    width: 350,
    height: 68,
    borderRadius: 12,
    marginBottom: 20,
  },
  continueText: {
    color: '#FFF',
    fontSize: 18,
  },
});
