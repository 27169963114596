/* eslint-disable @typescript-eslint/no-explicit-any */

import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';

const MAX_FILE_SIZE = 10388608;
const IMAGE_VALID_FILES = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg'];

export const imageValidation = (imageType: string, isRequired: boolean): MixedSchema => {
  let mixed = yup
    .mixed()
    .test('FILE_SIZE', 'El archivo debe pesar menos de 10mb', (value) => !value || value.size <= MAX_FILE_SIZE)
    .test(
      'FILE_TYPE',
      'El archivo debe ser jpeg, png, jpg, gif o svg',
      (value) => !value || IMAGE_VALID_FILES.includes(value.type),
    );
  if (isRequired) {
    mixed = mixed.required(
      `${imageType === 'foto' ? 'La' : 'El'} ${imageType} es ${imageType === 'foto' ? 'requerida' : 'requerido'}`,
    );
  }
  return mixed;
};

export const fileValidation = (): MixedSchema => {
  const mixed = yup
    .mixed()
    .test('FILE_SIZE', 'El archivo debe pesar menos de 10mb', (value) => !value || value.size <= MAX_FILE_SIZE)
    .required('El comprobante de discapacidad es requerido');
  return mixed;
};

export const enabledValidation = yup.boolean().required('Es requerido seleccionar si vive o no a más de 150 km');
