import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { Button } from 'react-native-elements';
import { Formik } from 'formik';
import { styles } from 'styles/forms/loginForm';
import loginValidationSchema from './schemas/loginForm';
import useMediumScreen from 'hooks/useMediumScreen';
import useOrientationPortrait from 'hooks/useOrientationPortrait';
import useChangeAuthData from 'hooks/useChangeAuthData';
import { loginPartner } from 'services/partnerService';

export default (): JSX.Element => {
  const mediumScreen = useMediumScreen();
  const orientationPort = useOrientationPortrait();
  const { storeAuthData } = useChangeAuthData();
  const [showInvalidCodeMessage, setShowInvalidCodeMessage] = React.useState<boolean>(false);

  const saveAuthData = async (email: string, password: string) => {
    const authData = await loginPartner(email, password);

    if (authData) {
      await storeAuthData(authData);
    } else {
      setShowInvalidCodeMessage(true);
    }
  };

  return (
    <Formik
      validationSchema={loginValidationSchema}
      initialValues={{ email: '', password: '' }}
      onSubmit={(values) => saveAuthData(values.email, values.password)}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, touched }) => (
        <>
          {showInvalidCodeMessage && <Text style={styles.invalidSubmit}>Correo electrónico o contraseña erronea.</Text>}
          <View style={mediumScreen || orientationPort ? styles.formContainerPortrait : styles.formContainerLandscape}>
            <TextInput
              placeholder="Email"
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              style={mediumScreen ? styles.inputContainerLarge : styles.inputContainerSmall}
              placeholderTextColor="#2A2A2A"
              keyboardType="email-address"
            />
            {errors.email && touched.email && <Text style={styles.errorText}>{errors.email}</Text>}
            <TextInput
              placeholder="Contraseña"
              onChangeText={handleChange('password')}
              onBlur={handleBlur('password')}
              value={values.password}
              style={mediumScreen ? styles.inputContainerLarge : styles.inputContainerSmall}
              placeholderTextColor="#2A2A2A"
              secureTextEntry={true}
              keyboardType="default"
            />
            {errors.password && touched.password && <Text style={styles.errorText}>{errors.password}</Text>}
            <Button
              onPress={() => handleSubmit()}
              title="Ingresar"
              type="solid"
              buttonStyle={styles.button}
              titleStyle={styles.buttonTitle}
              containerStyle={mediumScreen ? styles.buttonContainerLarge : styles.buttonContainerSmall}
              disabled={!isValid}
            />
          </View>
        </>
      )}
    </Formik>
  );
};
