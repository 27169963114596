import * as yup from 'yup';

export default yup.object().shape({
  cardNumber: yup.string().required('El número de tarjeta es requerido'),
  paymentMethodId: yup.number().positive('El tipo de tarjeta es requerido'),
  cardHolderName: yup
    .string()
    .required('El nombre es requerido')
    .max(26, 'Longitud máxima alcanzada')
    .matches(/^[aA-zZ\s]+$/, 'Solamente se permiten letras'),
  cardExpirationDate: yup.string().required('La fecha de expiración de la tarjeta es requerida'),
  cardSecurityCode: yup.string().required('El código de seguridad de la tarjeta es requerido'),
  cardHolderIdentificationNumber: yup.string().required('El número de la tarjeta es requerido'),
});
