import React from 'react';
import { Image, Text, TextInput, View, ScrollView, Platform, Switch, Keyboard, TouchableOpacity } from 'react-native';
import { CheckBox } from 'react-native-elements';
import { Button, Icon } from 'react-native-elements';
import { useFormik } from 'formik';
import { Picker } from '@react-native-picker/picker';
import { ImagePickerOptions, MediaTypeOptions, launchImageLibraryAsync } from 'expo-image-picker';
import { manipulateAsync } from 'expo-image-manipulator';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { getInfoAsync } from 'expo-file-system';
import * as DocumentPicker from 'expo-document-picker';
import { BACKOFFICE_WEB_URL } from '@env';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/screens/partnerFormScreen';
import { countries } from 'utils/countries';
import { parseDateToFormik } from 'utils/datetime';
import { verifyCuit } from 'utils/partners';
import useLogin from 'hooks/useLogin';
import partnerFormSchema from 'forms/schemas/partnerFormSchema';
import {
  storePartner,
  checkExistingPartner,
  checkExistingPartnerEmail,
  getSubsidiaries,
  getCardDeliveryOptions,
  checkExistingPartnerTicket,
} from 'services/partnerService';
import ImageType from 'types/enums/ImageType';
import IdentificationType from 'types/enums/IdentificationType';
import PartnerStatus from 'types/enums/PartnerStatus';
import PartnerSex from 'types/enums/PartnerSex';
import ContinuePaymentAlert from 'components/ContinuePaymentAlert';
import Footer from 'components/Footer';
import LoginOperator from 'components/LoginOperator';
import ForeignPartnerAlert from 'components/ForeignPartnerAlert';
import type PartnerData from 'types/models/PartnerData';
import type NativeImage from 'types/models/NativeImage';
import type Subsidiary from 'types/models/Subsidiary';
import type CardDeliveryOption from 'types/models/CardDeliveryOption';
import type PartnerFormScreenProps from 'types/screens/PartnerFormScreenProps';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import LogoutIcon from 'assets/icons/LogoutIcon';
import useCashPayment from 'hooks/useCashPayment';
import { UserLocationState } from 'types/hooks/useLogin';
import App from 'constants/App';

export default ({ route, navigation }: PartnerFormScreenProps): JSX.Element => {
  const { storeCashPayment } = useCashPayment();
  const {
    setIsLoading,
    modalizeData,
    setModalizeData,
    setCashPayment,
    cashPayment,
    setModalData,
    modalData,
    loggedUser,
  } = React.useContext(AppContext);
  const [photoImage, setPhotoImage] = React.useState<ImageInfo | null>(null);
  const [identificationFrontImage, setIdentificationFrontImage] = React.useState<ImageInfo | null>(null);
  const [identificationBackImage, setIdentificationBackImage] = React.useState<ImageInfo | null>(null);
  const [subsidiaries, setSubsidiaries] = React.useState<Subsidiary[] | null>(null);
  const [cardDeliveryOptions, setCardDeliveryOptions] = React.useState<CardDeliveryOption[] | null>(null);
  const [errorIdentification, setErrorIdentification] = React.useState<string | null>(null);
  const [errorUniqueIdentificationCode, setErrorUniqueIdentificationCode] = React.useState<string | null>(null);
  const [errorExistingEmail, setErrorExistingEmail] = React.useState<string | null>(null);
  const [errorExistingTicket, setErrorExistingTicket] = React.useState<string | null>(null);
  const [discapacityDocument, setDiscapacityDocument] = React.useState<DocumentPicker.DocumentResult | null>(null);
  const [userLocaltion, setUserLocation] = React.useState<UserLocationState | null>(null);
  const [isClientLivingAbroad, setIsClientLivingAbroad] = React.useState<boolean>(false);
  const params = route.params;
  const { logout, userLocationState } = useLogin();
  const cashPaymentActivated = params?.cashPayment ? parseInt(params?.cashPayment) === 1 : false;

  const onConfirm = async (): Promise<void> => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('surname', formik.values.surname);
    formData.append('name', formik.values.name);
    if (formik.values.identificationType) {
      formData.append('identificationType', formik.values.identificationType);
    }
    formData.append('identificationNumber', formik.values.identificationNumber);
    if (
      formik.values.uniqueIdentificationCode &&
      formik.values.uniqueIdentificationCode.length > 10 &&
      !errorUniqueIdentificationCode
    ) {
      formData.append('uniqueIdentificationCode', formik.values.uniqueIdentificationCode);
    }
    formData.append('dateOfBirth', formik.values.dateOfBirth);
    formData.append('nationality', formik.values.nationality);
    formData.append('telephoneNumber', formik.values.telephoneNumber);
    formData.append('mobilePhoneNumber', formik.values.mobilePhoneNumber);
    formData.append('sex', formik.values.sex);
    formData.append('email', formik.values.email);
    formData.append('photo', formik.values.photo as unknown as File);
    formData.append('identificationFront', formik.values.identificationFront as unknown as File);
    formData.append('identificationBack', formik.values.identificationBack as unknown as File);
    if (cashPayment) {
      formData.append('isCash', formik.values.isCash ? '1' : '0');
      formData.append('isScholarshipMember', formik.values.isScholarshipMember ? '1' : '0');
      if (formik.values.ticketNumber) {
        formData.append('ticketNumber', formik.values.ticketNumber);
      }
    }
    formData.append('country', formik.values.country);
    formData.append('postalCode', formik.values.postalCode);
    formData.append('locality', formik.values.locality);
    formData.append('province', formik.values.province);
    formData.append('street', formik.values.street);
    formData.append('streetNumber', formik.values.streetNumber);
    if (formik.values.subsidiary) {
      formData.append('subsidiary', formik.values.subsidiary);
    }
    if (!isClientLivingAbroad) {
      formData.append('cardDeliveryOption', formik.values.cardDeliveryOption);
    }
    formData.append('livesFarAway', formik.values.livesFarAway ? '1' : '0');
    formData.append('hasDiscapacity', formik.values.hasDiscapacity ? '1' : '0');
    formData.append('autoDebit', formik.values.autoDebit.toString());
    formData.append('isClientLivingAbroad', isClientLivingAbroad ? '1' : '0');

    if (formik.values.hasDiscapacity && formik.values.discapacityFile) {
      formData.append('discapacityFile', formik.values.discapacityFile);
    }

    if (formik.values.tower) {
      formData.append('tower', formik.values.tower);
    }

    formData.append('floor', formik.values.floor);
    formData.append('department', formik.values.department);

    formData.append('createdBy', `${cashPayment ? loggedUser?.user.id : null}`);
    formData.append('branchId', `${cashPayment ? loggedUser?.user.branchId : null}`);
    formData.append('ticketOfficeId', `${cashPayment ? loggedUser?.user.ticketOfficeId : null}`);

    try {
      if (params?.cashPayment) {
        setCashPayment(parseInt(params?.cashPayment) === 1);
      }
      const result = await storePartner(formData);
      setIsLoading(false);

      if (result) {
        formik.setSubmitting(false);
        const priceToNumber = parseFloat(`${result.price}`);
        if (cashPayment || !priceToNumber) {
          navigation.navigate('SuccessPaymentScreen', {
            partnerId: result.id,
          });
        } else if (result.autoDebit) {
          navigation.navigate('PartnerCreditCardFormScreen', {
            partnerId: result.id,
            email: result.email,
            price: result.price,
            name: result.name,
            surname: result.surname,
            identificationNumber: result.identificationNumber,
            identificationType: result.identificationType,
          });
        } else {
          navigation.navigate('PaymentScreen', {
            partnerId: result.id,
            email: result.email,
            price: result.price,
            name: result.name,
            surname: result.surname,
            identificationNumber: result.identificationNumber,
            identificationType: result.identificationType,
          });
        }
      }
    } catch (error) {
      formik.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const formik = useFormik<PartnerData>({
    initialValues: {
      surname: '',
      name: '',
      identificationType: undefined,
      identificationNumber: '',
      uniqueIdentificationCode: '',
      sex: '',
      dateOfBirth: '',
      nationality: '',
      telephoneNumber: '',
      mobilePhoneNumber: '',
      email: '',
      photo: null,
      identificationFront: null,
      identificationBack: null,
      country: '',
      postalCode: '',
      locality: '',
      province: '',
      street: '',
      streetNumber: '',
      tower: '',
      floor: '',
      department: '',
      livesFarAway: false,
      hasDiscapacity: false,
      autoDebit: false,
      discapacityFile: null,
      subsidiary: '',
      cardDeliveryOption: '',
      isCash: false,
      isScholarshipMember: false,
      ticketNumber: '',
    },
    onSubmit: () => {
      setModalData({
        partnerData: formik.values,
        cashPaymentForm: cashPayment,
        onContinueSubmit: () => onConfirm(),
        onCancel: () => {
          formik.setSubmitting(false);
          setModalData(null);
        },
      });
    },
    validationSchema: partnerFormSchema(cashPayment, isClientLivingAbroad),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const loadImage = async (imageType: ImageType, resultImage: ImageInfo): Promise<void> => {
    const getFieldToUpdate = () => {
      switch (imageType) {
        case ImageType.Photo:
          return 'photo';
        case ImageType.IdentificationFront:
          return 'identificationFront';
        case ImageType.IdentificationBack:
          return 'identificationBack';
        default:
          return null;
      }
    };

    const manipulateImageResult = resultImage && (await manipulateAsync(resultImage.uri, [], { compress: 0.2 }));
    const fieldToUpdate: string | null = getFieldToUpdate();
    if (!fieldToUpdate || !manipulateImageResult) {
      return;
    }
    try {
      let imageFile: Blob | NativeImage;
      const fileExtension = manipulateImageResult.uri.substr(manipulateImageResult.uri.lastIndexOf('.') + 1);
      if (Platform.OS === 'web') {
        const dataURL = `${manipulateImageResult.base64}`;
        const fetchResponse = await fetch(dataURL);
        imageFile = await fetchResponse.blob();
      } else {
        const fileInfo = await getInfoAsync(manipulateImageResult.uri);
        if (!resultImage.type) return;
        imageFile = {
          uri: Platform.OS === 'ios' ? manipulateImageResult.uri.replace('file://', '') : manipulateImageResult.uri,
          name: `load.${fileExtension}`,
          type: `${resultImage.type}/${fileExtension}`,
          size: fileInfo.size,
        };
      }
      formik.setFieldValue(fieldToUpdate, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const getImageTypeToUpdate = (imageType: ImageType, resultImage: ImageInfo): ImageType | null => {
    switch (imageType) {
      case ImageType.Photo:
        setPhotoImage(resultImage);
        return ImageType.Photo;
      case ImageType.IdentificationFront:
        setIdentificationFrontImage(resultImage);
        return ImageType.IdentificationFront;
      case ImageType.IdentificationBack:
        setIdentificationBackImage(resultImage);
        return ImageType.IdentificationBack;
      default:
        return null;
    }
  };

  const pickImage = async (imageType: ImageType): Promise<void> => {
    const imagePickerOptions: ImagePickerOptions = {
      mediaTypes: MediaTypeOptions.All,
      allowsEditing: false,
      quality: 0.1,
      base64: true,
    };
    const resultImage = await launchImageLibraryAsync(imagePickerOptions);
    if (resultImage && !resultImage.cancelled) {
      const resultImageType = getImageTypeToUpdate(imageType, resultImage);
      if (resultImageType) {
        loadImage(imageType, resultImage);
      }
    }
  };

  React.useEffect(() => {
    const storingCashPayment = async () => {
      if (cashPaymentActivated) {
        await storeCashPayment(cashPaymentActivated);
      }
    };
    storingCashPayment();
  }, [cashPaymentActivated]);

  React.useEffect(() => {
    (async () => {
      const state = await userLocationState();
      if (state) setUserLocation(state);
    })();
  }, [loggedUser]);

  React.useEffect(() => {
    const getAllSubsidiaries = async () => {
      const result = await getSubsidiaries();
      if (result) {
        setSubsidiaries(result);
      }
    };

    const getAllCardDeliveryOptions = async () => {
      const result = await getCardDeliveryOptions();
      if (result) {
        setCardDeliveryOptions(result);
      }
    };

    const placeholderSetterForClientsLivingAbroad = () => {
      setIsClientLivingAbroad(false);
    };

    getAllSubsidiaries();
    getAllCardDeliveryOptions();
    placeholderSetterForClientsLivingAbroad();
  }, []);

  const disabledConfirmButton = (): boolean => {
    if (errorIdentification) return true;
    if (errorUniqueIdentificationCode) return true;
    if (errorExistingEmail) return true;
    if (errorExistingTicket) return true;
    if (formik.isSubmitting) return true;

    return false;
  };

  const DateTimePicker = (value: string) => {
    return React.createElement('input', {
      type: 'date',
      value: value,
      onInput: (event: any) => {
        formik.setFieldValue('dateOfBirth', parseDateToFormik(event.target.value));
      },
      placeholder: 'Selecciona tu fecha de nacimiento',
      style: { height: 38, borderRadius: 10, fontSize: 18, marginTop: 8, backgroundColor: '#CECDBF' },
    });
  };

  const filterSociosCaiFromCountriesOfResidenceList = (): { name: string; code: string }[] => {
    if (isClientLivingAbroad) {
      return countries.filter((country) => country.name !== 'Argentina');
    } else {
      return countries;
    }
  };

  return (
    <>
      {!loggedUser && <LoginOperator isCashPaymentActive={cashPaymentActivated} />}
      <ScrollView style={styles.container} scrollEnabled={!modalizeData && !modalData}>
        <View style={{ backgroundColor: '#2A2A2A' }}>
          <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
          {loggedUser && (
            <TouchableOpacity onPress={() => logout()} style={{ position: 'absolute', top: 40, right: 20 }}>
              <LogoutIcon fill="#fff" width={30} height={30} />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.presentationContainer}>
          {loggedUser && cashPayment ? (
            <View style={styles.presentationTextContainer}>
              <Text style={styles.presentationTitle}>{`${loggedUser.user.name} ${loggedUser.user.lastName} 👋`}</Text>
              <Text style={styles.presentationSubTitle}>{`Sucursal - ${userLocaltion?.branch?.name ?? ''}`}</Text>
              <Text style={[styles.presentationSubTitle, { marginBottom: 20 }]}>{`Boleteria - ${
                userLocaltion?.ticketOffice?.name ?? ''
              }`}</Text>
              <Text
                style={[styles.presentationSubTitle, styles.administrativeText, { marginBottom: 20 }]}
                onPress={() => {
                  window.open(`${BACKOFFICE_WEB_URL}?token=${loggedUser.token}`, '_blank');
                }}
              >
                Ir a la administración de socios
              </Text>
            </View>
          ) : (
            <>
              <View style={styles.presentationTextContainer}>
                <Text style={styles.presentationTitle}>¡Me quiero Asociar!</Text>
                <Text style={styles.presentationSubTitle}>
                  Completá el siguiente formulario para darte de alta en esta gran familia roja
                </Text>
              </View>
              <View style={styles.loginSectionContainer}>
                {/*!isClientLivingAbroad && (
                  <Text
                    style={styles.foreignClientText}
                    onPress={() => {
                      setIsClientLivingAbroad(!isClientLivingAbroad);
                      formik.resetForm();
                      if (formik.isSubmitting) {
                        formik.setSubmitting(false);
                      }
                    }}
                  >
                    Si vivís en el exterior, hace clic acá
                  </Text>
                  )*/}
                <Text style={styles.alreadyPartnerText}>¿Ya sos socio? Podés ingresar con tus datos acá</Text>
                <Button
                  containerStyle={styles.confirmPaymentBtnContainer}
                  buttonStyle={styles.submitButton}
                  titleStyle={styles.submitTitle}
                  title="Ingresar"
                  disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
                  onPress={(): void => {
                    navigation.navigate('LoginScreen');
                  }}
                  disabled={disabledConfirmButton()}
                />
              </View>
              <View style={styles.informationLinksContainer}>
                <Text
                  onPress={() => {
                    window.open(App.faqsUrl, '_blank');
                  }}
                  style={styles.informationLinkText}
                >
                  Preguntas frecuentes
                </Text>
              </View>
            </>
          )}
        </View>
        <View style={styles.formContainer}>
          <View>
            <Text style={styles.labelTextTitle}>Datos Personales</Text>
          </View>
          <View style={styles.separatorLine} />
          <Text style={styles.labelTextInput}>
            Apellido <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            style={styles.textInputField}
            onChangeText={formik.handleChange('surname')}
            placeholder={'Ingresá tu apellido'}
            value={formik.values.surname}
          ></TextInput>
          {formik.errors.surname && formik.errors.surname.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.surname}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Nombre <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            style={styles.textInputField}
            onChangeText={formik.handleChange('name')}
            placeholder={'Ingresá tu nombre'}
            value={formik.values.name}
          ></TextInput>
          {formik.errors.name && formik.errors.name.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.name}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Dirección de correo electrónico <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="email-address"
            style={styles.textInputField}
            onChangeText={formik.handleChange('email')}
            placeholder={'Ingresá tu dirección de correo electrónico'}
            value={formik.values.email}
            onBlur={async () => {
              if (!formik.errors.email && formik.values.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                try {
                  const result = await checkExistingPartnerEmail(formik.values.email);
                  if (result) {
                    setErrorExistingEmail(null);
                  }
                } catch (error: any) {
                  console.log(error);
                  setErrorExistingEmail(`${error}`);
                }
              }
            }}
          ></TextInput>
          {formik.errors.email && formik.errors.email.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.email}</Text>
          )}
          <View>
            {errorExistingEmail && (
              <>
                <Text style={styles.errorText}>{errorExistingEmail}</Text>
              </>
            )}
          </View>
          <Text style={styles.labelTextInput}>
            Tipo de documento <Text style={styles.requiredField}>*</Text>
          </Text>
          {formik.errors.identificationType && formik.errors.identificationType.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.identificationType}</Text>
          )}
          <Picker
            selectedValue={formik.values.identificationType}
            onValueChange={(value) => {
              formik.setFieldValue('identificationType', value);
            }}
            style={styles.inputSelectContainer}
          >
            {!formik.values.identificationType && <Picker.Item label={'Seleccioná tipo de documento'} value={-1} />}
            {isClientLivingAbroad ? (
              <Picker.Item label={'Otro Documento'} value={IdentificationType.OD} />
            ) : (
              <>
                <Picker.Item label={'LE'} value={IdentificationType.LE} />
                <Picker.Item label={'CI'} value={IdentificationType.CI} />
              </>
            )}
            <Picker.Item label={isClientLivingAbroad ? 'Pasaporte Argentino' : 'PA'} value={IdentificationType.PA} />
            <Picker.Item label={isClientLivingAbroad ? 'DNI Argentino' : 'DNI'} value={IdentificationType.DNI} />
          </Picker>
          <Text style={styles.labelTextInput}>
            Número de documento <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="number-pad"
            style={styles.textInputField}
            onChangeText={formik.handleChange('identificationNumber')}
            value={formik.values.identificationNumber}
            onFocus={() => setErrorIdentification(null)}
            placeholder={'Ingresá tu número de documento'}
            maxLength={formik.values.identificationType === IdentificationType.OD ? undefined : 9}
            onBlur={async () => {
              try {
                if (formik.values.identificationNumber.length) {
                  const result = await checkExistingPartner(formik.values.identificationNumber);
                  if (result.partnerExists) {
                    setErrorIdentification('Ya existe un socio con ese número de identificación');
                  }
                  if (result.partnerExists && result.partner?.statusId === PartnerStatus.PaymentPending) {
                    setModalizeData({
                      content: (
                        <ContinuePaymentAlert
                          onContinuePayment={() => {
                            setModalizeData(null);
                            navigation.navigate('PaymentScreen', {
                              partnerId: result.partner?.id,
                              email: result.partner?.email,
                              price: result.price,
                              name: result.partner?.name,
                              surname: result.partner?.surname,
                              identificationNumber: result.partner?.identificationNumber,
                              identificationType: result.partner?.identificationType,
                            });
                          }}
                          onCancel={() => setModalizeData(null)}
                        />
                      ),
                      closeOnOverlayTap: true,
                    });
                  }
                }
              } catch (error: any) {
                console.log(error);
                setErrorIdentification(`${error}`);
              }
            }}
          ></TextInput>
          {formik.errors.identificationNumber && formik.errors.identificationNumber.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.identificationNumber}</Text>
          )}
          <View>
            {errorIdentification && (
              <>
                <Text style={styles.errorText}>{errorIdentification}</Text>
              </>
            )}
          </View>
          {!isClientLivingAbroad && (
            <>
              <Text style={styles.labelTextInput}>
                CUIL/CUIT <Text style={styles.requiredField}>*</Text>
              </Text>
              <TextInput
                keyboardType="number-pad"
                style={styles.textInputField}
                onChangeText={formik.handleChange('uniqueIdentificationCode')}
                value={formik.values.uniqueIdentificationCode}
                placeholder={'Ingresá el número de CUIT/CUIL (sin guiones)'}
                onBlur={() => {
                  if (formik.values.uniqueIdentificationCode) {
                    const result = verifyCuit(formik.values.uniqueIdentificationCode);
                    if (result) {
                      setErrorUniqueIdentificationCode(null);
                    } else {
                      setErrorUniqueIdentificationCode('Error: El CUIL/CUIT ingresado es incorrecto');
                    }
                  } else {
                    setErrorUniqueIdentificationCode(null);
                  }
                }}
              ></TextInput>
              {formik.errors.uniqueIdentificationCode && formik.errors.uniqueIdentificationCode.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.uniqueIdentificationCode}</Text>
              )}
              <View>
                {errorUniqueIdentificationCode && (
                  <>
                    <Text style={styles.errorText}>{errorUniqueIdentificationCode}</Text>
                  </>
                )}
              </View>
            </>
          )}
          <View>
            <Text style={styles.labelTextInput}>
              Sexo <Text style={styles.requiredField}>*</Text>
            </Text>
            <CheckBox
              center={true}
              title={'Masculino'}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={formik.values.sex === '1'}
              checkedColor={'#EC1C24'}
              onPress={() => formik.setFieldValue('sex', PartnerSex.Male)}
              textStyle={styles.labelTextInput}
            />
            <CheckBox
              center={true}
              title={'Femenino'}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={formik.values.sex === '2'}
              checkedColor={'#EC1C24'}
              onPress={() => formik.setFieldValue('sex', PartnerSex.Female)}
              textStyle={styles.labelTextInput}
            />
            {formik.errors.sex && formik.errors.sex.length > 0 && (
              <Text style={styles.errorText}>{formik.errors.sex}</Text>
            )}
          </View>
          <Text style={styles.labelTextInput}>
            Nacionalidad <Text style={styles.requiredField}>*</Text>
          </Text>
          <Picker
            selectedValue={formik.values.nationality}
            onValueChange={(value) => {
              formik.setFieldValue('nationality', value);
            }}
            style={styles.inputSelectContainer}
          >
            {!formik.values.nationality && <Picker.Item label={'Seleccioná un país'} value={-1} />}
            {countries.map((country, index) => (
              <Picker.Item key={index} label={country.name} value={country.name} />
            ))}
          </Picker>
          {formik.errors.nationality && formik.errors.nationality.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.nationality}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Fecha de nacimiento <Text style={styles.requiredField}>*</Text>
          </Text>
          {DateTimePicker(formik.values.dateOfBirth)}
          {formik.errors.dateOfBirth && formik.errors.dateOfBirth.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.dateOfBirth}</Text>
          )}
          <View>
            <Text style={styles.labelTextTitle}>{`${
              isClientLivingAbroad ? 'Datos de Residencia' : 'Datos de dirección'
            }`}</Text>
          </View>
          <View style={styles.separatorLine} />
          <Text style={styles.labelTextInput}>
            Calle <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            style={styles.textInputField}
            onChangeText={formik.handleChange('street')}
            placeholder={'Ingresá tu calle'}
            value={formik.values.street}
          ></TextInput>
          {formik.errors.street && formik.errors.street.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.street}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Número de calle <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            style={styles.textInputField}
            onChangeText={formik.handleChange('streetNumber')}
            placeholder={'Ingresá tu número de calle'}
            value={formik.values.streetNumber}
          ></TextInput>
          {formik.errors.streetNumber && formik.errors.streetNumber.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.streetNumber}</Text>
          )}
          <View style={styles.directionExtrasContainer}>
            <View style={[styles.extraDirectionField, isClientLivingAbroad && { width: '40%' }]}>
              <Text style={styles.labelTextInput}>Piso</Text>
              <TextInput
                keyboardType="default"
                style={styles.textInputField}
                onChangeText={formik.handleChange('floor')}
                placeholder={'Ingresá tu piso'}
                value={formik.values.floor}
              ></TextInput>
            </View>
            <View style={[styles.extraDirectionField, isClientLivingAbroad && { width: '40%' }]}>
              <Text style={styles.labelTextInput} numberOfLines={1}>
                {isClientLivingAbroad ? 'Departamento' : 'Depto'}
              </Text>
              <TextInput
                keyboardType="default"
                style={styles.textInputField}
                onChangeText={formik.handleChange('department')}
                placeholder={'Ingresá tu departamento'}
                value={formik.values.department}
              ></TextInput>
            </View>
            {!isClientLivingAbroad && (
              <View style={styles.extraDirectionField}>
                <Text style={styles.labelTextInput}>Torre</Text>
                <TextInput
                  keyboardType="default"
                  style={styles.textInputField}
                  onChangeText={formik.handleChange('tower')}
                  placeholder={'Ingresá tu torre (si aplica)'}
                  value={formik.values.tower}
                ></TextInput>
              </View>
            )}
          </View>
          {formik.errors.floor && formik.errors.floor.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.floor}</Text>
          )}
          {formik.errors.department && formik.errors.department.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.department}</Text>
          )}
          {formik.errors.tower && formik.errors.tower.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.tower}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Código Postal <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="number-pad"
            style={styles.textInputField}
            onChangeText={formik.handleChange('postalCode')}
            placeholder={'Ingresá tu código postal'}
            value={formik.values.postalCode}
          ></TextInput>
          {formik.errors.postalCode && formik.errors.postalCode.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.postalCode}</Text>
          )}
          <Text style={styles.labelTextInput}>
            {`${isClientLivingAbroad ? 'Ciudad' : 'Localidad'}`}
            <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            style={styles.textInputField}
            onChangeText={formik.handleChange('locality')}
            placeholder={isClientLivingAbroad ? 'Ingresá tu Ciudad' : 'Ingresá tu localidad'}
            value={formik.values.locality}
          ></TextInput>
          {formik.errors.locality && formik.errors.locality.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.locality}</Text>
          )}
          <Text style={styles.labelTextInput}>
            {`${isClientLivingAbroad ? 'Estado' : 'Provincia'}`}
            <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            style={styles.textInputField}
            onChangeText={formik.handleChange('province')}
            placeholder={isClientLivingAbroad ? 'Ingresá tu Estado' : 'Ingresá tu provincia'}
            value={formik.values.province}
          ></TextInput>
          {formik.errors.province && formik.errors.province.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.province}</Text>
          )}
          <Text style={styles.labelTextInput}>
            {`País ${isClientLivingAbroad ? 'de residencia' : ''}`}
            <Text style={styles.requiredField}>*</Text>
          </Text>
          <Picker
            selectedValue={formik.values.country}
            onValueChange={(value) => {
              const sociosCaiOriginCountry = 'Argentina';
              if (value !== sociosCaiOriginCountry) {
                setModalizeData({
                  content: <ForeignPartnerAlert onCancel={() => setModalizeData(null)} />,
                  closeOnOverlayTap: true,
                });
              }
              formik.setFieldValue('country', value);
            }}
            style={styles.inputSelectContainer}
          >
            {!formik.values.country && <Picker.Item label={'Seleccioná un país'} value={-1} />}
            {filterSociosCaiFromCountriesOfResidenceList().map((country, index) => (
              <Picker.Item key={index} label={country.name} value={country.name} />
            ))}
          </Picker>
          {formik.errors.country && formik.errors.country.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.country}</Text>
          )}
          <Text style={styles.labelTextInput}>
            {`${
              isClientLivingAbroad
                ? 'Número de teléfono (con prefijo del país [comenzando con +] y la ciudad)'
                : 'Celular'
            }`}
            <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="phone-pad"
            style={styles.textInputField}
            onChangeText={formik.handleChange('mobilePhoneNumber')}
            placeholder={'Ingresá tu número de teléfono celular'}
            value={formik.values.mobilePhoneNumber}
          ></TextInput>
          {formik.errors.mobilePhoneNumber && formik.errors.mobilePhoneNumber.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.mobilePhoneNumber}</Text>
          )}
          {!isClientLivingAbroad && (
            <>
              <Text style={styles.labelTextInput}>Teléfono </Text>
              <TextInput
                keyboardType="phone-pad"
                style={styles.textInputField}
                onChangeText={formik.handleChange('telephoneNumber')}
                placeholder={'Ingresá tu número de teléfono fijo'}
                value={formik.values.telephoneNumber}
              ></TextInput>
              {formik.errors.telephoneNumber && formik.errors.telephoneNumber.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.telephoneNumber}</Text>
              )}
              <View style={styles.switchContainer}>
                <Text style={styles.labelTextInput}>
                  ¿Vive a más de 150 Kms? <Text style={styles.requiredField}>*</Text>
                </Text>
                <Switch
                  value={formik.values.livesFarAway}
                  onValueChange={(value) => {
                    formik.setFieldValue('livesFarAway', value);
                  }}
                  trackColor={{
                    false: '#CECDBF',
                    true: '#CECDBF',
                  }}
                  thumbColor="#2A2A2A"
                  disabled={formik.isSubmitting}
                  style={styles.switch}
                />
                {formik.errors.livesFarAway && formik.errors.livesFarAway.length > 0 && (
                  <Text style={styles.errorText}>{formik.errors.livesFarAway}</Text>
                )}
              </View>
              <View style={styles.switchContainer}>
                <Text style={styles.labelTextInput}>
                  ¿Tiene una discapacidad? <Text style={styles.requiredField}>*</Text>
                </Text>
                <Switch
                  value={formik.values.hasDiscapacity}
                  onValueChange={(value) => {
                    formik.setFieldValue('hasDiscapacity', value);
                  }}
                  trackColor={{
                    false: '#CECDBF',
                    true: '#CECDBF',
                  }}
                  thumbColor={'#2A2A2A'}
                  disabled={formik.isSubmitting}
                  style={styles.switch}
                />
                {formik.errors.hasDiscapacity && formik.errors.hasDiscapacity.length > 0 && (
                  <Text style={styles.errorText}>{formik.errors.hasDiscapacity}</Text>
                )}
              </View>
              {formik.values.hasDiscapacity && (
                <>
                  {discapacityDocument?.type === 'success' && (
                    <View style={styles.discapacityDocumentTextContainer}>
                      <Text
                        style={styles.discapacityDocumentText}
                      >{`Comprobante de Discapacidad: ${discapacityDocument.name} `}</Text>
                    </View>
                  )}
                  <Button
                    buttonStyle={styles.attachmentButton}
                    titleStyle={styles.attachmentButtonTitle}
                    title="Subir certificado de discapacidad"
                    disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
                    onPress={async (): Promise<void> => {
                      const uploadedDocument = await DocumentPicker.getDocumentAsync({
                        type: '*/*',
                        copyToCacheDirectory: true,
                      });
                      if (uploadedDocument && uploadedDocument.type !== 'cancel') {
                        setDiscapacityDocument(uploadedDocument);
                        formik.setFieldValue('discapacityFile', uploadedDocument.file);
                      }
                    }}
                    disabled={disabledConfirmButton()}
                    icon={<Icon name="attachment" type="entypo" size={28} />}
                    iconPosition={'right'}
                  />
                  {formik.errors.discapacityFile && formik.errors.discapacityFile.length > 0 && (
                    <Text style={styles.errorText}>{formik.errors.discapacityFile}</Text>
                  )}
                </>
              )}
              <Text style={styles.labelTextInput}>{'¿Pertenece a una peña? (si aplica)'}</Text>
              {formik.errors.subsidiary && formik.errors.subsidiary.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.subsidiary}</Text>
              )}
              <Picker
                selectedValue={formik.values.subsidiary}
                onValueChange={(value) => {
                  formik.setFieldValue('subsidiary', value);
                }}
                style={styles.inputSelectContainer}
              >
                <Picker.Item label={'Seleccioná una peña'} value={''} />
                {subsidiaries?.map((subsidiary, index) => (
                  <Picker.Item
                    key={index}
                    label={`${subsidiary.description}, ${subsidiary.city}`}
                    value={subsidiary.id}
                  />
                ))}
              </Picker>
              <Text style={styles.labelTextInput}>
                Entrega de Carnet <Text style={styles.requiredField}>*</Text>
              </Text>
              {formik.errors.cardDeliveryOption && formik.errors.cardDeliveryOption.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.cardDeliveryOption}</Text>
              )}
              <Picker
                selectedValue={formik.values.cardDeliveryOption}
                onValueChange={(value) => {
                  formik.setFieldValue('cardDeliveryOption', value);
                }}
                style={styles.inputSelectContainer}
              >
                <Picker.Item label={'Seleccioná una opción'} value={''} />
                {cardDeliveryOptions?.map((cardDeliveryOption, index) => (
                  <Picker.Item key={index} label={`${cardDeliveryOption.description}`} value={cardDeliveryOption.id} />
                ))}
              </Picker>
            </>
          )}
          <View>
            <Text style={styles.labelTextTitle}>Documentación de respaldo</Text>
          </View>
          <View style={styles.separatorLine} />
          <View>
            <Text style={styles.labelTextInput}>
              Foto para carnet <Text style={styles.requiredField}>*</Text>
            </Text>
            {photoImage && (
              <Image
                source={{ uri: `data:image/jpeg;base64,${photoImage.base64}` }}
                style={{ width: 350, height: 350 }}
              />
            )}
            {formik.errors.photo && formik.errors.photo.length > 0 && (
              <Text style={styles.errorText}>{formik.errors.photo}</Text>
            )}
            <Button
              buttonStyle={styles.attachmentButton}
              titleStyle={styles.attachmentButtonTitle}
              title="Adjuntá una foto"
              disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
              onPress={(): void => {
                pickImage(ImageType.Photo);
              }}
              icon={<Icon name="attachment" type="entypo" size={28} />}
              iconPosition={'right'}
            />
          </View>
          <View>
            <Text style={styles.labelTextInput}>
              {`${isClientLivingAbroad ? 'Foto del documento (frente)' : 'DNI frente'}`}
              <Text style={styles.requiredField}>*</Text>
            </Text>
            {identificationFrontImage && (
              <Image
                source={{ uri: `data:image/jpeg;base64,${identificationFrontImage.base64}` }}
                style={{ width: 350, height: 350 }}
              />
            )}
            {formik.errors.identificationFront && formik.errors.identificationFront.length > 0 && (
              <Text style={styles.errorText}>{formik.errors.identificationFront}</Text>
            )}
            <Button
              buttonStyle={styles.attachmentButton}
              titleStyle={styles.attachmentButtonTitle}
              title="Adjuntá imagen del frente del DNI"
              disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
              onPress={(): void => {
                pickImage(ImageType.IdentificationFront);
              }}
              icon={<Icon name="attachment" type="entypo" size={28} />}
              iconPosition={'right'}
            />
          </View>
          <View>
            <Text style={styles.labelTextInput}>
              {`${isClientLivingAbroad ? 'Foto del documento (dorso)' : 'DNI dorso'}`}
              <Text style={styles.requiredField}>*</Text>
            </Text>
            {identificationBackImage && (
              <Image
                source={{ uri: `data:image/jpeg;base64,${identificationBackImage.base64}` }}
                style={{ width: 350, height: 350 }}
              />
            )}
            {formik.errors.identificationBack && formik.errors.identificationBack.length > 0 && (
              <Text style={styles.errorText}>{formik.errors.identificationBack}</Text>
            )}
            <Button
              buttonStyle={styles.attachmentButton}
              titleStyle={styles.attachmentButtonTitle}
              title="Adjuntá imagen del dorso del DNI"
              disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
              onPress={(): void => {
                pickImage(ImageType.IdentificationBack);
              }}
              icon={<Icon name="attachment" type="entypo" size={28} />}
              iconPosition={'right'}
            />
          </View>
          {cashPayment ? (
            <View>
              <CheckBox
                center={true}
                title={'¿Es Pago Efectivo?'}
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checked={formik.values.isCash}
                checkedColor={'#EC1C24'}
                onPress={() => {
                  formik.setFieldValue('isCash', !formik.values.isCash);
                  formik.setFieldValue('isScholarshipMember', false);
                }}
                textStyle={styles.labelTextInput}
              />
              <CheckBox
                center={true}
                title={'¿Es becado?'}
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checked={formik.values.isScholarshipMember}
                checkedColor={'#EC1C24'}
                onPress={() => {
                  formik.setFieldValue('isScholarshipMember', !formik.values.isScholarshipMember);
                  formik.setFieldValue('isCash', false);
                }}
                textStyle={styles.labelTextInput}
              />
              {formik.errors.isCash && formik.errors.isCash.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.isCash}</Text>
              )}
              {formik.errors.isScholarshipMember && formik.errors.isScholarshipMember.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.isScholarshipMember}</Text>
              )}
              <Text style={styles.labelTextInput}>
                Número de ticket <Text style={styles.requiredField}>*</Text>
              </Text>
              <TextInput
                keyboardType="default"
                style={styles.textInputField}
                onChangeText={formik.handleChange('ticketNumber')}
                placeholder={'Ingresá el número de ticket'}
                value={formik.values.ticketNumber}
                onBlur={async () => {
                  try {
                    if (formik.values.ticketNumber?.length) {
                      const result = await checkExistingPartnerTicket(formik.values.ticketNumber);
                      if (result) {
                        setErrorExistingTicket(null);
                      }
                    }
                  } catch (error: any) {
                    console.log(error);
                    setErrorExistingTicket(`${error}`);
                  }
                }}
              ></TextInput>
              {formik.errors.ticketNumber && formik.errors.ticketNumber.length > 0 && (
                <Text style={styles.errorText}>{formik.errors.ticketNumber}</Text>
              )}
              <View>
                {errorExistingTicket && (
                  <>
                    <Text style={styles.errorText}>{errorExistingTicket}</Text>
                  </>
                )}
              </View>
            </View>
          ) : (
            <>
              {!isClientLivingAbroad && (
                <View style={styles.switchContainer}>
                  <Text style={styles.labelTextInput}>
                    Deseo adherirme al débito automático <Text style={styles.requiredField}>*</Text>
                  </Text>
                  <Switch
                    value={formik.values.autoDebit}
                    onValueChange={(value) => {
                      formik.setFieldValue('autoDebit', value);
                    }}
                    trackColor={{
                      false: '#CECDBF',
                      true: '#CECDBF',
                    }}
                    thumbColor="#2A2A2A"
                    disabled={formik.isSubmitting}
                    style={styles.switch}
                  />
                  {formik.errors.autoDebit && formik.errors.autoDebit.length > 0 && (
                    <Text style={styles.errorText}>{formik.errors.autoDebit}</Text>
                  )}
                </View>
              )}
            </>
          )}
          <Button
            containerStyle={styles.confirmPaymentBtnContainer}
            buttonStyle={styles.submitButton}
            titleStyle={styles.submitTitle}
            title="Enviar Formulario"
            disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
            onPress={(): void => {
              Keyboard.dismiss();
              formik.handleSubmit();
            }}
            disabled={disabledConfirmButton()}
          />
        </View>
        <Footer />
      </ScrollView>
    </>
  );
};
