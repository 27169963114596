import React from 'react';
import { Modal, View } from 'react-native';
import ModalContainerProps from 'types/components/ModalContainerProps';
import { styles } from 'styles/components/modalContainer';

export default ({ isVisible, content }: ModalContainerProps): JSX.Element => {
  return (
    <Modal visible={isVisible} animationType="slide" transparent={true}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={{ maxHeight: '30%' }}>{content}</View>
        </View>
      </View>
    </Modal>
  );
};
