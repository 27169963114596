import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  failurePaymentContent: {
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    justifyContent: 'center',
  },
  confirmPaymentBtnContainer: {
    marginTop: 35,
    margin: 16,
    width: '90%',
    alignSelf: 'center',
    bottom: 0,
    borderRadius: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  },
  failurePaymentText: {
    fontWeight: '400',
    fontSize: 18,
  },
  goToLoginBtnContainer: {
    height: 90,
    backgroundColor: '#EC1C24',
  },
  goToLoginBtn: {
    padding: 10,
  },
  failurePaymentTextButton: {
    color: '#FFFFFF',
    fontWeight: '400',
    fontSize: 22,
    lineHeight: 17,
  },
});
