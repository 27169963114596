import React from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import FormFDProps from 'types/components/FormFDProps';
import { FIRST_DATA_URL } from '@env';

export default ({
  webHost,
  partnerId,
  recurringPaymentId,
  timeZone,
  dateTime,
  hash,
  fdStore,
  currency,
  chargeTotal,
  setIsLoading,
  setResultPayment,
}: FormFDProps): JSX.Element => {
  const resolveErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case '03':
      case '12':
      case '13':
        return 'Ocurrio un error con la transacción. Pruebe nuevamente dentro de unos minutos.';
      case '51':
        return 'Fondos insuficientes, pruebe con otro medio de pago.';
      case '14':
      case '2304':
        return 'Los datos de la tarjeta son incorrectos. Pruebe nuevamente.';
      default:
        return 'Ocurrio un error con la transacción. Debe contactarse con el proveedor de su tarjeta o probar con otro medio de pago.';
    }
  };

  React.useEffect(() => {
    const onMessage = (event: any) => {
      let success = false;
      let response;
      const elementArr = event.data?.elementArr;
      if (elementArr) {
        const response_code = elementArr.find((element: any) => element.name === 'processor_response_code');
        if (response_code && response_code.value === '00') {
          success = true;
        }
        response = response_code && response_code.value ? resolveErrorMessage(response_code.value) : undefined;
        setResultPayment({ success, response });
      }
    };
    window.addEventListener('message', onMessage);
    setIsLoading(true);
    return () => window.removeEventListener('message', onMessage);
  }, []);

  // Revisar responseSuccessURL | responseFailURL para Native
  const html = recurringPaymentId
    ? `<html>
    <body onload="document.forms[0].submit();">
    <form action="${FIRST_DATA_URL}" method="post" target="_self">
      <input type="hidden" name="partnerId" value="${partnerId}"/>
      <input type="hidden" name="recurringPaymentId" value="${recurringPaymentId}"/>
      <input type="hidden" name="txntype" value="sale"/>
      <input type="hidden" name="timezone" value="${timeZone}"/>
      <input type="hidden" name="txndatetime" value="${dateTime}"/>
      <input type="hidden" name="hash_algorithm" value="SHA256"/>
      <input type="hidden" name="hash" value="${hash}"/>
      <input type="hidden" name="storename" value="${fdStore}" />
      <input type="hidden" name="mode" value="payonly"/>
      <input type="hidden" name="currency" value="${currency}"/>
      <input type="hidden" name="mobileMode" value="true"/>
      <input type="hidden" name="chargetotal" value="${chargeTotal}" />
      <input type="hidden" name="hostURI" value="${webHost}fd-payment" />
      <input type="hidden" name="checkoutoption" value="simpleform" />
      <input type="hidden" name="responseSuccessURL" value="${webHost}" />
      <input type="hidden" name="responseFailURL" value="${webHost}" />
    </form>
  </html>`
    : `<html>
  <body onload="document.forms[0].submit();">
  <form action="${FIRST_DATA_URL}" method="post" target="_self">
    <input type="hidden" name="partnerId" value="${partnerId}"/>
    <input type="hidden" name="txntype" value="sale"/>
    <input type="hidden" name="timezone" value="${timeZone}"/>
    <input type="hidden" name="txndatetime" value="${dateTime}"/>
    <input type="hidden" name="hash_algorithm" value="SHA256"/>
    <input type="hidden" name="hash" value="${hash}"/>
    <input type="hidden" name="storename" value="${fdStore}" />
    <input type="hidden" name="mode" value="payonly"/>
    <input type="hidden" name="currency" value="${currency}"/>
    <input type="hidden" name="mobileMode" value="true"/>
    <input type="hidden" name="chargetotal" value="${chargeTotal}" />
    <input type="hidden" name="hostURI" value="${webHost}fd-payment" />
    <input type="hidden" name="checkoutoption" value="simpleform" />
    <input type="hidden" name="responseSuccessURL" value="${webHost}" />
    <input type="hidden" name="responseFailURL" value="${webHost}" />
  </form>
</html>`;

  if (Platform.OS === 'web') {
    // Se usa css instile porque es un html element
    return (
      <iframe
        style={{ border: 0, borderWidth: 0 }}
        srcDoc={html}
        height="100%"
        width="100%"
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    );
  }
  return <WebView source={{ html }} />;
};
