import * as React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import AppContext from 'contexts/AppContext';
import useCashPayment from 'types/hooks/useCashPayment';

export default (): useCashPayment => {
  const { setCashPayment } = React.useContext(AppContext);

  const storeCashPayment = async (cashPaymentActivated: boolean): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.cashPayment, JSON.stringify(cashPaymentActivated));
      setCashPayment(cashPaymentActivated);
    } catch (error) {
      console.log(error);
    }
  };

  const getCashPayment = async (): Promise<boolean> => {
    const isCashPaymentActive = await AsyncStorage.getItem(constantsStorage.cashPayment);
    if (isCashPaymentActive) {
      const parseIsCashPaymentActive: boolean = JSON.parse(isCashPaymentActive);
      if (parseIsCashPaymentActive) {
        return true;
      }
    }
    return false;
  };
  return { storeCashPayment, getCashPayment };
};
