export const verifyCuit = (cuit: string) => {
  const zerosTestRegex = /0{3,}[^\/]*$/; // Chequea por ceros consecutivos en CUIT/CUIL

  if (cuit.length !== 11) {
    return false;
  }

  if (zerosTestRegex.test(cuit)) {
    return false;
  }

  let acumulado = 0;
  const digitos = cuit.split('');
  const digito = parseInt(digitos.pop() ?? '');

  for (let i = 0; i < digitos.length; i++) {
    acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
  }

  let verif = 11 - (acumulado % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }

  return digito === verif;
};
