import React from 'react';
import { Animated, View } from 'react-native';
import type { FormStepCarouselProps, FormStepsProps, Step } from 'types/components/StepsProps';

export const FormStepCarousel = ({ children, step }: FormStepCarouselProps) => {
  const animView = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const view = animView.current as HTMLDivElement;
    const maxY = view.scrollWidth;
    if (step === 1) view.scrollLeft = maxY;
  }, [step]);

  return (
    <Animated.View
      ref={animView}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        overflow: 'hidden',
        width: '450px',
      }}
    >
      {React.Children.map(children, (child, index) => (
        <View key={index}>{child}</View>
      ))}
    </Animated.View>
  );
};

export const FormSteps = ({ currStep = 0, totalSteps = 2, passedSteps }: FormStepsProps): JSX.Element => {
  const isPassed = (index: number) => passedSteps.includes(index) || currStep === index;

  const Step = ({ index = 0 }: Step) => {
    return (
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isPassed(index) ? '#ED5156' : '#9C9C9C',
          marginHorizontal: 12,
          borderRadius: 10,
          width: 40,
          height: 5,
        }}
      />
    );
  };

  const steps: JSX.Element[] = [];
  Array.from(Array(totalSteps)).forEach((v, index) => {
    steps[index] = Step({ index: index });
  });

  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginVertical: 20,
      }}
    >
      {steps.map((step, index) => (
        <React.Fragment key={index}>{step}</React.Fragment>
      ))}
    </View>
  );
};
