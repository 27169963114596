import React from 'react';
import { View, Image, TextInput } from 'react-native';
import { Text, Button } from 'react-native-elements';
import { useFocusEffect } from '@react-navigation/native';
import SuccessModal from 'components/SuccessModal';
import Footer from 'components/Footer';
import { getPartner, storeNewPartnerPassword } from 'services/partnerService';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import { styles } from 'styles/screens/passwordRecoveryScreen';
import AppContext from 'contexts/AppContext';
import PasswordRecoveryScreenProps from 'types/screens/PasswordRecoveryScreenProps';
import useChangeAuthData from 'hooks/useChangeAuthData';

export default ({ route, navigation }: PasswordRecoveryScreenProps): JSX.Element => {
  const params = route.params;
  const { storeAuthData } = useChangeAuthData();
  const { setIsLoading, setModalizeData } = React.useContext(AppContext);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [duplicateNewPassword, setDuplicateNewPassword] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<string>('');

  const buttonDisabled = (): boolean => {
    if (newPassword.length < 7 && duplicateNewPassword.length < 7) return true;
    if (newPassword !== duplicateNewPassword) return true;
    if (passwordError.length) return true;
    return false;
  };

  useFocusEffect(
    React.useCallback(() => {
      if (!params || !params.partnerId || !params.partnerName) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'LoginScreen' }],
          });
        }, 500);
      }
    }, []),
  );

  React.useEffect(() => {
    const checkPartnerId = async (): Promise<void> => {
      const partnerExists = await getPartner(params.partnerId);
      if (partnerExists) {
        return;
      } else {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'LoginScreen' }],
          });
        }, 500);
      }
    };
    checkPartnerId();
  }, []);
  console.log(buttonDisabled());
  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      <Text style={styles.title}>Reestablecer la Contraseña</Text>
      <Text
        style={styles.subTitle}
      >{`Hola ${params.partnerName} abajo tenes las instrucciones para establecer tu nueva contraseña:`}</Text>
      <View style={styles.contentContainer}>
        <Text style={styles.passwordrequirementText}>La Contraseña debe tener 8 caracteres o más *</Text>
        <Text style={styles.textInputTitle}>Nueva Contraseña</Text>
        <TextInput
          keyboardType="default"
          style={styles.textInputField}
          onChangeText={(password) => {
            setDuplicateNewPassword('');
            setNewPassword(password);
          }}
          placeholder={'Nueva Contraseña'}
          value={newPassword}
          secureTextEntry={true}
        />
        <Text style={styles.textInputTitle}>Confirmá tu nueva Contraseña</Text>
        <TextInput
          keyboardType="default"
          style={styles.textInputField}
          onChangeText={setDuplicateNewPassword}
          placeholder={'Volvé a repetir tu nueva contraseña'}
          value={duplicateNewPassword}
          secureTextEntry={true}
          editable={newPassword.length >= 7 ? true : false}
          onBlur={() => {
            if (duplicateNewPassword.length >= 7) {
              if (newPassword !== duplicateNewPassword) {
                setPasswordError('La contraseña debe ser igual a la ingresada previamente');
              } else {
                setPasswordError('');
              }
            }
          }}
        />
        {passwordError && <Text style={styles.errorText}>{passwordError}</Text>}
        <Button
          title={'Cambiar Contraseña'}
          containerStyle={styles.passwordRecoveryButtonContainer}
          buttonStyle={styles.passwordRecoveryButton}
          titleStyle={styles.passwordRecoveryTitle}
          disabledStyle={[styles.passwordRecoveryButton, styles.passwordRecoveryDisabled]}
          disabled={buttonDisabled()}
          onPress={async () => {
            if (newPassword) {
              setIsLoading(true);
              const partnerWithNewPassword = await storeNewPartnerPassword(params.partnerId, newPassword);
              if (partnerWithNewPassword) {
                setIsLoading(false);
                const message = `Estimado ${params.partnerName}, su contraseña fue cambiada exitosamente, tu nueva contraseña es ${newPassword}`;
                setModalizeData({
                  content: (
                    <SuccessModal
                      isSuccess={true}
                      title={'Cambio de Contraseña Exitoso'}
                      mainColor={'#EC1C24'}
                      message={message}
                      buttonText={'Ir al Carnet'}
                      onConfirm={async () => {
                        setModalizeData(null);
                        await storeAuthData(partnerWithNewPassword);
                      }}
                    />
                  ),
                  closeOnOverlayTap: false,
                });
              } else {
                setIsLoading(false);
                const message = 'No se pudo hacer su cambio de contraseña, intente de vuelta';
                setModalizeData({
                  content: (
                    <SuccessModal
                      isSuccess={true}
                      title={'Cambio de Contraseña Fallido'}
                      mainColor={'#EC1C24'}
                      message={message}
                      buttonText={'Volver'}
                      onConfirm={() => {
                        setModalizeData(null);
                      }}
                    />
                  ),
                  closeOnOverlayTap: false,
                });
              }
            }
          }}
        />
      </View>
      <Footer />
    </View>
  );
};
