import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  informationLinksContainerForFooter: {
    marginTop: 5,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    textAlign: 'flex-start',
    marginLeft: 30,
    marginBottom: 15,
  },
  informationLinkText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#FFFFFF',
  },
});
