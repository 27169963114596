import React from 'react';
import { Text, View, Modal, ScrollView } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { AntDesign } from '@expo/vector-icons';
import FormSummaryAlertProps from 'types/components/FormSummaryAlertProps';
import PartnerSex from 'types/enums/PartnerSex';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/formSummaryAlert';

export default ({
  partnerData,
  partnerCreditCardData,
  cashPaymentForm,
  onContinueSubmit,
  onCancel,
}: FormSummaryAlertProps): JSX.Element => {
  const { modalData, setModalData } = React.useContext(AppContext);
  const isVisible = modalData ? true : false;

  return (
    <View style={styles.modalContainer}>
      <Modal style={styles.modal} visible={isVisible} animationType="slide">
        <ScrollView>
          <Card.Title style={styles.legendText}>Resumen de datos ingresados</Card.Title>
          <Card.Divider style={[styles.titleDivider]} />
          <View style={styles.iconsContainer}>
            <AntDesign name="exclamationcircle" size={48} color={'#f00'} />
          </View>
          <Card.FeaturedSubtitle style={styles.legendText}>
            Verificá los datos que ingresaste en el formulario
          </Card.FeaturedSubtitle>
          <View style={styles.textContainer}>
            {partnerData && (
              <>
                <Text style={styles.message}>{`Nombre y apellido: ${partnerData.name} ${partnerData.surname}`}</Text>
                <Text style={styles.message}>{`Email: ${partnerData.email}`}</Text>
                <Text
                  style={styles.message}
                >{`Tipo y número de documento: ${partnerData.identificationType} ${partnerData.identificationNumber}`}</Text>
                <Text style={styles.message}>{`CUIT/CUIL: ${partnerData.uniqueIdentificationCode}`}</Text>
                <Text style={styles.message}>{`Sexo: ${
                  partnerData.sex === PartnerSex.Female ? 'Femenino' : 'Masculino'
                }`}</Text>
                <Text style={styles.message}>{`Nacionalidad: ${partnerData.nationality}`}</Text>
                <Text style={styles.message}>{`Fecha de nacimiento: ${partnerData.dateOfBirth}`}</Text>
                <Text
                  style={styles.message}
                >{`Calle y altura: ${partnerData.street} ${partnerData.streetNumber}`}</Text>
                {partnerData.telephoneNumber.length > 1 && (
                  <Text style={styles.message}>{`Teléfono fijo: ${partnerData.telephoneNumber}`}</Text>
                )}
                <Text style={styles.message}>{`Teléfono celular: ${partnerData.mobilePhoneNumber}`}</Text>
                <Text style={styles.message}>{`Pais: ${partnerData.country}`}</Text>
                <Text style={styles.message}>{`Código postal: ${partnerData.postalCode}`}</Text>
                <Text style={styles.message}>{`Provincia: ${partnerData.province}`}</Text>
                <Text style={styles.message}>{`Localidad: ${partnerData.locality}`}</Text>
                {partnerData.floor.length > 0 && <Text style={styles.message}>{`Piso: ${partnerData.floor}`}</Text>}
                {partnerData.department.length > 0 && (
                  <Text style={styles.message}>{`Departamento: ${partnerData.department}`}</Text>
                )}
                {partnerData?.tower && partnerData?.tower.length > 0 && (
                  <Text style={styles.message}>{`Torre: ${partnerData.tower}`}</Text>
                )}
                <Text style={styles.message}>{`Vivís a ${
                  partnerData.livesFarAway ? 'más de' : 'menos de'
                } 150 Kms`}</Text>
                <Text style={styles.message}>
                  {partnerData.hasDiscapacity ? 'Tenes una discapacidad' : 'No tenes discapacidad'}
                </Text>
                {partnerData?.subsidiary && partnerData?.subsidiary.length > 1 && (
                  <Text style={styles.message}>{`Peña: ${partnerData.subsidiary}`}</Text>
                )}
                {partnerData.autoDebit && (
                  <Text style={styles.message}>
                    {partnerData.autoDebit ? 'Adherís al débito automático' : 'No adherís al débito automático'}
                  </Text>
                )}
                {cashPaymentForm && (
                  <View>
                    {partnerData.isCash && <Text style={styles.message}>{'Pagás en efectivo'}</Text>}
                    {partnerData.isScholarshipMember && <Text style={styles.message}>{'Sos Becado'}</Text>}
                    <Text style={styles.message}>{`Ticket: ${partnerData.ticketNumber}`}</Text>
                  </View>
                )}
              </>
            )}
            {partnerCreditCardData && (
              <>
                <Text style={styles.message}>{partnerCreditCardData.creditCardBrand}</Text>
                <Text style={styles.message}>{partnerCreditCardData.creditCardNumber}</Text>
                <Text style={styles.message}>{partnerCreditCardData.creditCardCardHolderName}</Text>
                <Text style={styles.message}>{partnerCreditCardData.creditCardExpirationDate}</Text>
              </>
            )}
          </View>
          <View style={styles.buttonsContainer}>
            <Button
              title={'Continuar'}
              type="clear"
              buttonStyle={[styles.confirmContinuePaymentBtn]}
              titleStyle={styles.confirmContinuePaymentBtnText}
              onPress={() => {
                setModalData(null);
                onContinueSubmit();
              }}
            ></Button>
            <Button
              title={'Cancelar'}
              titleStyle={{ color: '#f00' }}
              containerStyle={styles.cancelBtn}
              type="clear"
              onPress={() => onCancel()}
            ></Button>
          </View>
        </ScrollView>
      </Modal>
    </View>
  );
};
