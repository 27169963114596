import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';

export const styles = StyleSheet.create({
  invalidSubmit: {
    color: '#EC1C24',
    fontSize: 18,
  },
  formContainerPortrait: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  formContainerLandscape: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  inputContainerLarge: {
    marginTop: 10,
    backgroundColor: '#CECDBF',
    fontSize: 18,
    borderRadius: 5,
    width: '100%',
    height: 60,
    alignSelf: 'center',
    color: '#2A2A2A',
    paddingLeft: 30,
  },
  inputContainerSmall: {
    marginTop: 10,
    backgroundColor: '#CECDBF',
    fontSize: 18,
    borderRadius: 5,
    width: wp('70%'),
    height: 60,
    alignSelf: 'center',
    color: '#2A2A2A',
    paddingLeft: 30,
  },
  button: {
    backgroundColor: '#EC1C24',
    borderRadius: 35,
    marginTop: 20,
    width: 250,
    height: 80,
  },
  buttonTitle: {
    color: '#FFFFFF',
    fontWeight: '400',
    fontSize: 22,
    lineHeight: 17,
  },
  buttonContainerLarge: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 10,
  },
  buttonContainerSmall: {
    width: wp('70%'),
    paddingRight: 10,
    paddingLeft: 10,
  },
  errorText: {
    width: wp('50%'),
    marginBottom: '2%',
    fontSize: 18,
    textAlign: 'center',
    color: '#EC1C24',
  },
});
