import React from 'react';
import { Text, View } from 'react-native';
import { Button, Card } from 'react-native-elements';
import ContinuePaymentAlertProps from 'types/components/ContinuePaymentAlertProps';
import { styles } from 'styles/components/continuePaymentAlert';
import { AntDesign } from '@expo/vector-icons';

export default ({ onContinuePayment, onCancel, recurringPayment }: ContinuePaymentAlertProps): JSX.Element => {
  return (
    <>
      <Card.Title style={styles.legendText}>
        {`${recurringPayment ? 'Cuota con pago no completado' : 'Registro de Socios'}`}{' '}
      </Card.Title>
      <Card.Divider style={[styles.titleDivider]} />
      <View style={styles.iconsContainer}>
        <AntDesign name="exclamationcircle" size={48} color={'#f00'} />
      </View>
      <View>
        <Text style={styles.message}>{`${
          recurringPayment
            ? `Este usuario tiene una cuota que no se terminó de pagar, el monto es ${recurringPayment.feeAmount} ¿desea pagar ahora?`
            : 'Este usuario existe pero falta realizar el pago, ¿quieres pagar ahora?'
        }`}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={'Pagar ahora'}
          type="clear"
          buttonStyle={[styles.confirmContinuePaymentBtn]}
          titleStyle={styles.confirmContinuePaymentBtnText}
          onPress={() => {
            onContinuePayment();
          }}
        ></Button>
        <Button
          title={'Cancelar'}
          titleStyle={{ color: '#f00' }}
          containerStyle={styles.cancelBtn}
          type="clear"
          onPress={() => onCancel()}
        ></Button>
      </View>
    </>
  );
};
