import React from 'react';
import { Image, Text, TextInput, View, ScrollView, Keyboard } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button, Icon } from 'react-native-elements';
import { useFormik } from 'formik';
import { Picker } from '@react-native-picker/picker';
import { DECIDIR_URL, DECIDIR_PUBLIC_API_KEY } from '@env';
import { storeDecidirPayment, getDecidirPaymentMethods } from 'services/decidirService';
import AppContext from 'contexts/AppContext';
import ResultPayment from 'types/models/ResultPayment';
import DecidirPaymentMethod from 'types/models/DecidirPaymentMethod';
import PartnerPaymentCardFormScreenProps from 'types/screens/PartnerPaymentCardFormScreenProps';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import partnerPaymentCardFormSchema from 'forms/schemas/partnerPaymentCardFormSchema';
import Footer from 'components/Footer';
import { styles } from 'styles/screens/partnerCreditCardFormScreen';
import PaymentCardData from 'types/models/PaymentCarddata';

export default ({ route }: PartnerPaymentCardFormScreenProps): JSX.Element => {
  const { setIsLoading } = React.useContext(AppContext);
  const [decidirPaymentMethods, setDecidirPaymentMethods] = React.useState<DecidirPaymentMethod[] | null>(null);
  const [tokenMessageForDecidir, setTokenMessageForDecidir] = React.useState<any>(null);
  const [getTokenDecidir, setGetTokenDecidir] = React.useState<boolean>(false);
  const [resultPayment, setResultPayment] = React.useState<ResultPayment | null>(null);
  const navigation = useNavigation();
  const params = route.params;
  const regexForOnlyNumbers = /[^\dA-Z]/g; // Regex para solo permitir números para tarjetas;
  const regexForDisplayCardNumbers = /(.{4})/g; // Regex para detectar números y ordenar de a 4 números;

  useFocusEffect(
    React.useCallback(() => {
      if (!params || !params.email || !params.partnerId || !params.price) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'PartnerFormScreen' }],
          });
        }, 500);
      }
    }, []),
  );

  React.useEffect(() => {
    const onMessage = (event: any) => {
      // identify correctness of message from iframe
      if (typeof event.data === 'string') {
        setTokenMessageForDecidir(JSON.parse(event.data));
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  React.useEffect(() => {
    const getAllDecidirPaymentMethods = async () => {
      const decidirPaymentMethods = await getDecidirPaymentMethods();
      if (decidirPaymentMethods) {
        setDecidirPaymentMethods(decidirPaymentMethods);
      }
    };
    getAllDecidirPaymentMethods();
  }, []);

  const onConfirm = async (): Promise<void> => {
    if (!params?.partnerId) return;
    setIsLoading(true);
    try {
      setGetTokenDecidir(true);
    } catch (error) {
      formik.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const formik = useFormik<PaymentCardData>({
    initialValues: {
      cardNumber: '',
      paymentMethodId: undefined,
      cardHolderName: '',
      cardExpirationDate: '',
      cardSecurityCode: '',
      cardHolderIdentificationNumber: '',
    },
    onSubmit: () => {
      onConfirm();
    },
    validationSchema: partnerPaymentCardFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const disabledConfirm = (): boolean => {
    if (formik.isSubmitting) return true;
    return false;
  };

  const expirationDatePattern = (value: string): string => {
    return value
      .replace(
        /[^0-9]/g,
        '', // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1', // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0', // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        '$1/$2', // To handle 113 > 11/3
      );
  };

  const html = `<html>
  <head>
  <script src="https://live.decidir.com/static/v2.5/decidir.js"></script>
  <script>
  var decidir = null
  const publicApiKey = "${DECIDIR_PUBLIC_API_KEY}";
  const url = "${DECIDIR_URL}";
  //Para el ambiente de desarrollo
  decidir = new Decidir(url, true);
  decidir.setPublishableKey(publicApiKey);
  decidir.setTimeout(1000);//timeout de 1 segundo

  function addEvent(el, eventName, handler){
    if (el.addEventListener) {
      console.log('hola event listener');
      el.addEventListener(eventName, handler);
    } else {
      console.log('hola attach event');
        el.attachEvent('on' + eventName, function(){
          handler.call(el);
        });
    }
  };

  function sdkResponseHandler(status, response) {
    if (status != 200 && status != 201) {
      console.log(status);
      //Manejo de error: Ver Respuesta de Error
      //...codigo...
    } else {
      window.parent.postMessage(JSON.stringify(response));
      //Manejo de respuesta donde response = {token: "99ab0740-4ef9-4b38-bdf9-c4c963459b22"}
      //..codigo...
    }
  }

  const sendForm = () => {
    var $form = document.querySelector('#formulario');
    decidir.createToken($form, sdkResponseHandler);//formulario y callback
    return false;
  }

  function guessingPaymentMethod() {

    var cardNumber = document.querySelector('input[data-decidir][name="card_number"]').value;
    var bin = decidir.getBin(cardNumber);
    
    var issuedInput = document.querySelector('input[name="issued"]');
  
    issuedInput.value = decidir.cardType(cardNumber);
    console.log(decidir.cardType(cardNumber));
    console.log('bin', bin);
  }

  /*function intializeExample() {
    guessingPaymentMethod();
    let form = document.querySelector('form[name=card-form]');
    addEvent(form,'submit',sendForm)
  }
  
  window.addEventListener("DOMContentLoaded", function() {
    intializeExample();
});*/

document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    guessingPaymentMethod();
    sendForm();
}
}
</script>
  </head>
  <body>
  <form action="" method="post" id="formulario" name="card-form">
  <fieldset>
		<ul>
      <li>
        <label for="card_number">Numero de tarjeta:</label>
        <input type="text" data-decidir="card_number" name="card_number" placeholder="XXXXXXXXXXXXXXXX" value=${formik.values.cardNumber
          .split(' ')
          .join('')} />
      </li>
      <li>
        <label for="security_code">Codigo de seguridad:</label>
      <input type="text"  data-decidir="security_code" placeholder="XXX" value=${formik.values.cardSecurityCode} />
      </li>
      <li>
        <label for="card_expiration_month">Mes de vencimiento:</label>
        <input type="text"  data-decidir="card_expiration_month" placeholder="MM" value=${
          formik.values.cardExpirationDate.split('/')[0]
        } />
      </li>
      <li>
        <label for="card_expiration_year">Año de vencimiento:</label>
        <input type="text"  data-decidir="card_expiration_year" placeholder="AA" value=${
          formik.values.cardExpirationDate.split('/')[1]
        } />
      </li>
      <li>
        <label for="card_holder_name">Nombre del titular:</label>
        <input type="text" data-decidir="card_holder_name" placeholder="TITULAR" value=${formik.values.cardHolderName
          .split(' ')
          .join('')} />
      </li>
      <li>
        <label for="card_holder_doc_type">Tipo de documento:</label>
        <select data-decidir="card_holder_doc_type">
					<option value="dni" selected>DNI</option>
				</select>
      </li>
      <li>
        <label for="card_holder_doc_type">Numero de documento:</label>
        <input type="text"data-decidir="card_holder_doc_number" placeholder="XXXXXXXXXX" value=${
          formik.values.cardHolderIdentificationNumber
        } />
      </li>
      <li>
        <label for="issued">Issued:</label>
        <input type="text" name="issued" disabled/>
      </li>
    </ul>
    <input type="submit" value="Generar Token" />
  </fieldset>
</form>
</html>`;

  React.useEffect(() => {
    if (params.retryPayment) {
      if (formik) formik.resetForm();
      if (tokenMessageForDecidir) setTokenMessageForDecidir(null);
      setGetTokenDecidir(false);
    }
  }, [params]);

  React.useEffect(() => {
    const storePayment = async () => {
      if (tokenMessageForDecidir) {
        setIsLoading(true);
        const decidirPaymentResult = await storeDecidirPayment({
          partnerId: params.partnerId,
          email: params.email,
          price: parseFloat(Number(params.price).toFixed(2)),
          token: tokenMessageForDecidir.id,
          bin: tokenMessageForDecidir.bin,
          cardNumber: formik.values.cardNumber.split(' ').join(''),
          paymentMethod: formik.values.paymentMethodId,
          cardExpirationMonth: tokenMessageForDecidir.expiration_month,
          cardExpirationYear: tokenMessageForDecidir.expiration_year,
          cardLastFourDigits: tokenMessageForDecidir.last_four_digits,
          cardHolderName: formik.values.cardHolderName,
        });

        if (decidirPaymentResult && decidirPaymentResult.decidir_payment) {
          if (decidirPaymentResult.decidir_payment.status === 'approved') {
            setResultPayment({ success: true });
          } else {
            setResultPayment({ success: false });
          }
        } else {
          setResultPayment({ success: false });
        }
      }
    };
    storePayment();
  }, [tokenMessageForDecidir]);

  React.useEffect(() => {
    if (resultPayment) {
      setIsLoading(false);
      formik.setSubmitting(false);
      if (resultPayment.success) {
        navigation.navigate('SuccessPaymentScreen', {
          partnerId: params.partnerId,
        });
      } else {
        navigation.navigate('FailurePaymentScreen', {
          partnerId: params.partnerId,
          email: params.email,
          price: params.price,
        });
      }
    }
  }, [resultPayment]);

  return (
    <>
      <ScrollView style={styles.container}>
        <View style={{ backgroundColor: '#2A2A2A' }}>
          <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
        </View>
        <View style={styles.arrowContainerSmall}>
          <Icon
            name="arrow-back-outline"
            type="ionicon"
            color="#EC1C24"
            containerStyle={styles.arrowIconSmall}
            onPress={() => navigation.goBack()}
          />
          <View>
            <Text style={styles.labelTextTitle}>
              Complete el siguiente formulario para registrar la tarjeta que será usada para efectuar el pago
            </Text>
          </View>
        </View>
        {getTokenDecidir && (
          <View style={{ flex: 1 }}>
            <iframe
              style={{ border: 0, borderWidth: 0, display: 'none' }}
              srcDoc={html}
              height="100%"
              width="100%"
              onLoad={() => {
                setIsLoading(false);
              }}
            />
          </View>
        )}
        <View style={styles.formContainer}>
          <Text style={styles.labelTextInput}>
            Número de Tarjeta <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="numeric"
            style={styles.textInputField}
            onChangeText={(value: string) =>
              formik.setFieldValue(
                'cardNumber',
                value.replace(regexForOnlyNumbers, '').replace(regexForDisplayCardNumbers, '$1 ').trim(),
              )
            }
            maxLength={19}
            value={formik.values.cardNumber}
            placeholder={'Ingresá tu número de tarjeta'}
          ></TextInput>
          {formik.errors.cardNumber && formik.errors.cardNumber.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.cardNumber}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Tipo de Tarjeta <Text style={styles.requiredField}>*</Text>
          </Text>
          {decidirPaymentMethods && (
            <Picker
              selectedValue={formik.values.paymentMethodId}
              onValueChange={(value) => {
                formik.setFieldValue('paymentMethodId', value);
              }}
              style={styles.inputSelectContainer}
            >
              {!formik.values.paymentMethodId && <Picker.Item label={'Seleccioná tipo de tarjeta'} value={''} />}
              {decidirPaymentMethods?.map((decidirPaymentMethod) => (
                <Picker.Item
                  key={decidirPaymentMethod.id}
                  label={decidirPaymentMethod.brand}
                  value={decidirPaymentMethod.paymentMethodId}
                />
              ))}
            </Picker>
          )}
          {formik.errors.paymentMethodId && formik.errors.paymentMethodId.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.paymentMethodId}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Mes y Año de Vto <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="numeric"
            placeholder="10/24"
            maxLength={5}
            style={styles.textInputField}
            onChangeText={(value: string) => formik.setFieldValue('cardExpirationDate', expirationDatePattern(value))}
            value={formik.values.cardExpirationDate}
          ></TextInput>
          {formik.errors.cardExpirationDate && formik.errors.cardExpirationDate.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.cardExpirationDate}</Text>
          )}
          <Text style={styles.labelTextInput}>
            Titular de Tarjeta (justo como aparece en la tarjeta) <Text style={styles.requiredField}>*</Text>
          </Text>
          <TextInput
            keyboardType="default"
            maxLength={26}
            style={styles.textInputField}
            onChangeText={(value) => formik.setFieldValue('cardHolderName', value.toUpperCase())}
            placeholder={'Ingresá tu Nombre'}
            value={formik.values.cardHolderName}
          ></TextInput>
          {formik.errors.cardHolderName && formik.errors.cardHolderName.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.cardHolderName}</Text>
          )}
          <Text style={styles.labelTextInput}>Codigo de Seguridad</Text>
          <TextInput
            style={[styles.textInputField]}
            keyboardType="numeric"
            placeholder="cvv"
            maxLength={4}
            onChangeText={(value: string) => formik.setFieldValue('cardSecurityCode', value)}
            value={formik.values.cardSecurityCode}
          />
          {formik.errors.cardSecurityCode && formik.errors.cardSecurityCode.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.cardSecurityCode}</Text>
          )}
          <Text style={styles.labelTextInput}>Número de Documento</Text>
          <TextInput
            style={[styles.textInputField]}
            keyboardType="numeric"
            placeholder="12345678"
            onChangeText={(value: string) => formik.setFieldValue('cardHolderIdentificationNumber', value)}
            value={formik.values.cardHolderIdentificationNumber}
          />
          {formik.errors.cardHolderIdentificationNumber && formik.errors.cardHolderIdentificationNumber.length > 0 && (
            <Text style={styles.errorText}>{formik.errors.cardHolderIdentificationNumber}</Text>
          )}
          <Button
            containerStyle={styles.confirmPaymentBtnContainer}
            buttonStyle={styles.submitButton}
            titleStyle={styles.submitTitle}
            title="Confirmar Datos de Tarjeta"
            disabledStyle={[styles.submitButton, styles.submitButtonDisabled]}
            onPress={(): void => {
              Keyboard.dismiss();
              formik.handleSubmit();
            }}
            disabled={disabledConfirm()}
          />
        </View>
        <Footer />
      </ScrollView>
    </>
  );
};
