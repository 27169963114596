import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  presentationContainer: {
    backgroundColor: '#2A2A2A',
  },
  presentationTextContainer: {
    alignSelf: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  presentationTitle: {
    marginTop: 10,
    fontSize: 28,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  presentationSubTitle: {
    marginTop: 15,
    fontSize: 24,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  formContainer: {
    padding: 20,
  },
  labelTextTitle: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: '400',
    color: '#2A2A2A',
  },
  labelTextInput: {
    marginTop: 5,
    fontSize: 18,
    fontWeight: '400',
    color: '#2A2A2A',
  },
  labelErrorInput: {
    marginTop: 5,
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#FF0000',
  },
  textInputField: {
    marginTop: 10,
    backgroundColor: '#CECDBF',
    height: 60,
    fontSize: 18,
    borderRadius: 5,
    padding: 10,
  },
  confirmPaymentBtnContainer: {
    alignSelf: 'center',
    bottom: 0,
    marginTop: 30,
    marginBottom: 15,
  },
  submitButton: {
    width: 250,
    height: 80,
    borderRadius: 35,
    alignSelf: 'center',
    backgroundColor: '#EC1C24',
  },
  submitButtonDisabled: {
    backgroundColor: '#c4c4c4',
  },
  submitTitle: {
    color: '#FFF',
    fontWeight: '400',
    fontSize: 24,
    justifyContent: 'flex-end',
  },
  errorText: {
    color: '#EC1C24',
    fontSize: 16,
  },
  inputSelectContainer: {
    height: 60,
    borderRadius: 10,
    fontSize: 18,
    marginTop: 8,
    backgroundColor: '#CECDBF',
  },
  requiredField: {
    color: '#EC1C24',
    fontSize: 20,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  separatorLine: {
    borderBottomColor: '#CECDBF',
    borderBottomWidth: 3,
    marginTop: 8,
  },
  extraDirectionField: {
    flexDirection: 'column',
    width: 85,
  },
  arrowIconSmall: {
    alignSelf: 'flex-start',
    flex: 1,
  },
  arrowContainerSmall: {
    flexDirection: 'column',
    padding: 10,
    top: 0,
    marginTop: 10,
  },
});
