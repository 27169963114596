import React from 'react';
import { View, Text, Image, ImageBackground } from 'react-native';
import { Icon, Button } from 'react-native-elements';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { useNavigation } from '@react-navigation/native';
import AppContext from 'contexts/AppContext';
import ImageType from 'types/enums/ImageType';
import Partner from 'types/models/Partner';
import Footer from 'components/Footer';
//import ContinuePaymentAlert from 'components/ContinuePaymentAlert';
import useChangeAuthData from 'hooks/useChangeAuthData';
import HeaderImage from 'assets/images/independiente_crest_plus_text.png';
import BackgroundImageIndependiente from 'assets/images/ID_Independiente.jpg';
//import { storeUserRecurringPayment, getPendingRecurringPayment } from 'services/partnerService';
import { styles } from 'styles/screens/homeScreen';

export default (): JSX.Element => {
  const navigation = useNavigation();
  const { deleteAuthData } = useChangeAuthData();
  const { authData, cashPayment, loggedUser } = React.useContext(AppContext);
  const profilePhoto = authData?.images.find((image) => image.partnerImageId === ImageType.Photo);
  const qrCodePartner = authData?.images.find((image) => image.partnerImageId === ImageType.QrCode);
  const lastRecurringPayment = authData?.recurringPayments?.find((recurringPayment) => recurringPayment);
  if (!authData) {
    return <></>;
  }
  console.log(cashPayment);
  console.log(loggedUser);
  const showRecurringPaymentInfo = (partner: Partner): boolean => {
    if (partner.autoDebit) return false;
    if (partner.category.id === 4) return false;
    if (partner.isScholarshipMember) return false;
    return true;
  };

  const showRegisterCreditCardButton = (partner: Partner): boolean => {
    if (!partner.autoDebit) return false;
    if (partner.creditCard) return false;
    if (partner.category.id === 4) return false;
    if (partner.isScholarshipMember) return false;
    return true;
  };

  const showCreateNewPartnerAsAdmin = (): boolean => {
    if (loggedUser && cashPayment) return true;
    return false;
  };

  return (
    <View style={styles.container}>
      <View style={{ backgroundColor: '#2A2A2A' }}>
        <Image style={{ height: 120, width: '100%' }} resizeMode={'contain'} source={HeaderImage} />
      </View>
      <View>
        <ImageBackground style={{ height: hp('35%'), width: '100%' }} source={BackgroundImageIndependiente}>
          {!showCreateNewPartnerAsAdmin() && (
            <View style={styles.headerSmall}>
              <Icon
                tvParallaxProperties={null}
                name="power-outline"
                type="ionicon"
                color="#FFFFFF"
                containerStyle={styles.goBackContainerSmall}
                onPress={async () => {
                  await deleteAuthData();
                }}
              />
            </View>
          )}
        </ImageBackground>
      </View>
      <View style={styles.qrImageContainer}>
        <Image source={{ uri: qrCodePartner?.path }} style={styles.qrImage} />
      </View>
      <View style={styles.containerPartnerCard}>
        <View style={styles.profileImageContainer}>
          <Image source={{ uri: profilePhoto?.path }} style={styles.image} />
        </View>
        <View style={styles.profileTextsContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.profileTextTitle}>{`NOMBRE Y APELLIDO`}</Text>
            <Text style={styles.profileTextDescription}>{`${authData.name} ${authData.surname}`}</Text>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.profileTextTitle}>{`DNI`}</Text>
            <Text style={styles.profileTextDescription}>{`${authData.identificationNumber}`}</Text>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.profileTextTitle}>{`SOCIO`}</Text>
            <Text style={styles.profileTextDescription}>{`${authData.partnerNumber}`}</Text>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.profileTextTitle}>{`CATEGORÍA`}</Text>
            <Text style={styles.profileTextDescription}>{`${authData.category.description}`}</Text>
          </View>
        </View>
      </View>
      {showCreateNewPartnerAsAdmin() && (
        <View style={styles.recurringPaymentInfoContainer}>
          <Button
            title={'Crear otro socio'}
            containerStyle={styles.recurringPaymentPaymentButtonContainer}
            buttonStyle={styles.recurringPaymentPaymentButton}
            titleStyle={styles.recurringPaymentPaymentTitle}
            onPress={async () => {
              await deleteAuthData();
            }}
          />
        </View>
      )}
      {showRecurringPaymentInfo(authData) && (
        <View style={styles.recurringPaymentInfoContainer}>
          {lastRecurringPayment && (
            <View style={{ marginBottom: 15, alignSelf: 'center' }}>
              <Text
                style={styles.profileTextTitle}
              >{`Número de cuotas pagadas actualmente: ${lastRecurringPayment.feeNumber}`}</Text>
              <Text style={styles.profileTextTitle}>{`Monto de la cuota: $${lastRecurringPayment.feeAmount}`}</Text>
            </View>
          )}
          {/*<Button
            title={'Pagar próxima cuota'}
            containerStyle={styles.recurringPaymentPaymentButtonContainer}
            buttonStyle={styles.recurringPaymentPaymentButton}
            titleStyle={styles.recurringPaymentPaymentTitle}
            onPress={async () => {
              const checkForPendingRecurringPayment = await getPendingRecurringPayment(authData.id);
              if (checkForPendingRecurringPayment) {
                setModalizeData({
                  content: (
                    <ContinuePaymentAlert
                      onContinuePayment={() => {
                        setModalizeData(null);
                        navigation.navigate('PaymentFDScreen', {
                          partnerId: authData.id,
                          price: checkForPendingRecurringPayment.feeAmount,
                          recurringPaymentId: checkForPendingRecurringPayment.id,
                        });
                      }}
                      onCancel={() => {
                        setModalizeData(null);
                        return;
                      }}
                      recurringPayment={checkForPendingRecurringPayment}
                    />
                  ),
                  closeOnOverlayTap: false,
                });
                return;
              }

              if (authData.charge) {
                const recurringPayment = await storeUserRecurringPayment(
                  authData.id,
                  Number(authData?.charge),
                  lastRecurringPayment ? lastRecurringPayment.id : undefined,
                );
                if (recurringPayment) {
                  navigation.navigate('PaymentFDScreen', {
                    partnerId: authData.id,
                    price: recurringPayment.feeAmount,
                    recurringPaymentId: recurringPayment.id,
                  });
                }
              }
            }}
          />*/}
        </View>
      )}
      {authData.creditCard && (
        <View style={styles.creditCardTextContainer}>
          <Text style={styles.profileTextTitle}>Usted esta adherido al debito automático</Text>
        </View>
      )}
      {showRegisterCreditCardButton(authData) && (
        <View style={styles.recurringPaymentInfoContainer}>
          <Button
            title={'Adherirse a Débito Automático'}
            containerStyle={styles.recurringPaymentPaymentButtonContainer}
            buttonStyle={styles.recurringPaymentPaymentButton}
            titleStyle={styles.recurringPaymentPaymentTitle}
            onPress={async () => {
              navigation.navigate('PartnerCreditCardFormScreen', {
                partnerId: authData.id,
                email: authData.email,
                name: authData.name,
                surname: authData.surname,
                identificationNumber: authData.identificationNumber,
                identificationType: authData.identificationType,
              });
            }}
          />
        </View>
      )}
      <Footer />
    </View>
  );
};
