import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modal: {
    position: 'absolute',
    borderWidth: 0,
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    alignItems: 'flex-start',
  },
  legendText: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: 18,
    lineHeight: 23,
    color: '#000',
    opacity: 0.8,
  },
  titleDivider: {
    borderBottomWidth: 1,
    borderBottomColor: '#f00',
  },
  message: {
    padding: 15,
    color: '#89909b',
    fontSize: 18,
  },
  buttonsContainer: {
    width: '100%',
    alignItems: 'center',
  },
  confirmContinuePaymentBtn: {
    backgroundColor: '#f00',
    width: 350,
    height: 68,
    borderRadius: 12,
    marginBottom: 20,
  },
  confirmContinuePaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  cancelBtn: {
    width: 300,
    fontSize: 18,
    marginBottom: 20,
  },
});
