/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Private: {
            screens: {
              HomeScreen: 'home',
            },
          },
          Public: {
            screens: {
              LoginScreen: 'login',
              PartnerFormScreen: 'partner-form',
              PaymentScreen: 'payment',
              SuccessPaymentScreen: 'success-payment',
              FailurePaymentScreen: 'failure-payment',
              PasswordRecoveryWithEmailScreen: 'recover-user-password',
              PasswordRecoveryScreen: 'recover-password',
              PartnerCreditCardFormScreen: 'partner-credit-card',
              PartnerPaymentCardFormScreen: 'partner-payment-card-form',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
};
