/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Modalize } from 'react-native-modalize';
import { SafeAreaView } from 'react-native-safe-area-context';

import Loading from 'components/Loading';
import FormSummaryAlert from 'components/FormSummaryAlert';
import ModalContainer from 'components/ModalContainer';
import LoginScreen from 'screens/LoginScreen';
import PartnerFormScreen from 'screens/PartnerFormScreen';
import PaymentScreen from 'screens/PaymentScreen';
import HomeScreen from 'screens/HomeScreen';
import SuccessPaymentScreen from 'screens/SuccessPaymentScreen';
import FailurePaymentScreen from 'screens/FailurePaymentScreen';
import PaymentFDScreen from 'screens/PaymentFDScreen';

import AppContext from 'contexts/AppContext';
import Partner from 'types/models/Partner';
import ModalizeData from 'types/models/ModalizeData';
import ModalData from 'types/models/ModalData';
import { PrivateStackParamList, PublicStackParamList, MainStackParamList } from 'types/navigation/stackNavigator';
import constantsStorage from 'constants/Storage';
import type AuthData from 'types/models/AuthData';
import useCashPayment from 'hooks/useCashPayment';
import PasswordRecoveryWithEmailScreen from 'screens/PasswordRecoveryWithEmailScreen';
import PasswordRecoveryScreen from 'screens/PasswordRecoveryScreen';
import PartnerCreditCardFormScreen from 'screens/PartnerCreditCardFormScreen';
import PartnerPaymentCardFormScreen from 'screens/PartnerPaymentCardFormScreen';

export default function BottomTabNavigator(): JSX.Element | null {
  const Stack = createStackNavigator<MainStackParamList>();
  const { getCashPayment } = useCashPayment();
  const modalizeRef = React.useRef<Modalize>(null);
  const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [authData, setAuthData] = React.useState<Partner | null>(null);
  const [loggedUser, setLoggedUser] = React.useState<AuthData | null>(null);
  const [modalizeData, setModalizeData] = React.useState<ModalizeData | null>(null);
  const [modalData, setModalData] = React.useState<ModalData | null>(null);
  const [cashPayment, setCashPayment] = React.useState<boolean | null>(null);

  const setStorageAuthData = async (): Promise<void> => {
    const authData = await AsyncStorage.getItem(constantsStorage.authData);
    if (authData) setAuthData(JSON.parse(authData));
  };

  const setStorageLoggedUser = async (): Promise<void> => {
    const loginUser = await AsyncStorage.getItem(constantsStorage.loggedUser);
    if (loginUser) setLoggedUser(JSON.parse(loginUser));
  };

  const setStorageCashPayment = async (): Promise<void> => {
    const isCashPaymentActivated = await getCashPayment();
    if (isCashPaymentActivated) {
      setCashPayment(isCashPaymentActivated);
    }
  };

  const loadInitalData = React.useCallback(async () => {
    await setStorageAuthData();
    await setStorageCashPayment();
    await setStorageLoggedUser();
    setInitialLoad(true);
  }, []);

  React.useEffect(() => {
    if (modalizeRef.current) {
      if (modalizeData) {
        modalizeRef.current.open();
      } else {
        modalizeRef.current.close();
      }
    }
  }, [modalizeData]);

  React.useEffect(() => {
    loadInitalData();
  }, []);

  if (!initialLoad) {
    return <Loading />;
  } else {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <AppContext.Provider
          value={{
            setIsLoading,
            authData,
            setAuthData,
            modalizeData,
            setModalizeData,
            cashPayment,
            setCashPayment,
            loggedUser,
            setLoggedUser,
            modalData,
            setModalData,
          }}
        >
          {isLoading && <Loading />}
          <Stack.Navigator>
            {authData ? (
              <>
                <Stack.Screen name="Private" component={PrivateNavigator} options={{ headerShown: false }} />
                <Stack.Screen
                  name="SuccessPaymentScreen"
                  component={SuccessPaymentScreen}
                  options={{ headerShown: false }}
                />
              </>
            ) : (
              <Stack.Screen name="Public" component={PublicNavigator} options={{ headerShown: false }} />
            )}
            <Stack.Screen name="PaymentFDScreen" component={PaymentFDScreen} options={{ headerShown: false }} />
            <Stack.Screen
              name="FailurePaymentScreen"
              component={FailurePaymentScreen}
              options={{ headerShown: false }}
            />
          </Stack.Navigator>
          {modalData && (
            <FormSummaryAlert
              partnerData={modalData.partnerData}
              partnerCreditCardData={modalData.partnerCreditCardData}
              cashPaymentForm={modalData.cashPaymentForm}
              onContinueSubmit={modalData.onContinueSubmit}
              onCancel={modalData.onCancel}
            />
          )}
          {modalizeData && (
            <ModalContainer isVisible={modalizeData ? true : false} content={modalizeData.content ?? <></>} />
          )}
        </AppContext.Provider>
      </SafeAreaView>
    );
  }
}
const PrivateStack = createStackNavigator<PrivateStackParamList>();

const PrivateNavigator = (): JSX.Element => {
  const [initialLoad, setInitialLoad] = React.useState<boolean>(false);

  React.useEffect(() => {
    setInitialLoad(true);
  }, []);

  if (!initialLoad) {
    return <Loading />;
  } else {
    return (
      <PrivateStack.Navigator>
        <PrivateStack.Screen name="HomeScreen" component={HomeScreen} options={{ headerShown: false }} />
        <PublicStack.Screen
          name="PartnerCreditCardFormScreen"
          component={PartnerCreditCardFormScreen}
          options={{ headerShown: false }}
        />
      </PrivateStack.Navigator>
    );
  }
};

const PublicStack = createStackNavigator<PublicStackParamList>();

const PublicNavigator = (): JSX.Element => {
  return (
    <PublicStack.Navigator>
      <PublicStack.Screen name="PartnerFormScreen" component={PartnerFormScreen} options={{ headerShown: false }} />
      <PublicStack.Screen name="PaymentScreen" component={PaymentScreen} options={{ headerShown: false }} />
      <PublicStack.Screen name="LoginScreen" component={LoginScreen} options={{ headerShown: false }} />
      <PublicStack.Screen
        name="PartnerPaymentCardFormScreen"
        component={PartnerPaymentCardFormScreen}
        options={{ headerShown: false }}
      />
      <PublicStack.Screen
        name="SuccessPaymentScreen"
        component={SuccessPaymentScreen}
        options={{ headerShown: false }}
      />
      <PublicStack.Screen
        name="FailurePaymentScreen"
        component={FailurePaymentScreen}
        options={{ headerShown: false }}
      />
      <PublicStack.Screen
        name="PasswordRecoveryWithEmailScreen"
        component={PasswordRecoveryWithEmailScreen}
        options={{ headerShown: false }}
      />
      <PublicStack.Screen
        name="PasswordRecoveryScreen"
        component={PasswordRecoveryScreen}
        options={{ headerShown: false }}
      />
      <PublicStack.Screen
        name="PartnerCreditCardFormScreen"
        component={PartnerCreditCardFormScreen}
        options={{ headerShown: false }}
      />
    </PublicStack.Navigator>
  );
};
