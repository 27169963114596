import axiosInstance from './axios';
import PaymentDataFD from 'types/models/PaymentDataFD';
import FirstDataConfig from 'types/models/FirstDataConfig';

export const getHash = async (paymentDataFD: PaymentDataFD): Promise<string | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'first-data/hash',
      data: {
        date_time: paymentDataFD.dateTime,
        charge_total: paymentDataFD.chargeTotal,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getConfig = async (): Promise<FirstDataConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'first-data/config',
    });
    return parseFirstDataConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseFirstDataConfig = (data: any): FirstDataConfig => {
  return {
    fdStore: data.fd_store,
    timeZone: data.time_zone,
    currency: data.currency,
  };
};
