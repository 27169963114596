export const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Islas Åland', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antartica', code: 'AQ' },
  { name: 'Antigua y Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Bielorrusia', code: 'BY' },
  { name: 'Bélgica', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Butan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia y Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Isla Bouvet', code: 'BV' },
  { name: 'Brasil', code: 'BR' },
  { name: 'Territorio Británico del Océano Índico', code: 'IO' },
  { name: 'Brunei', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Camerún', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cabo Verde', code: 'CV' },
  { name: 'islas Caimán', code: 'KY' },
  { name: 'Republica Centro Africana', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Isla de Navidad', code: 'CX' },
  { name: 'Islas Cocos', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoras', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Republica Democratica del Congo', code: 'CD' },
  { name: 'Islas Cook', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Costa de Marfil', code: 'CI' },
  { name: 'Croacia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Chipre', code: 'CY' },
  { name: 'Republica Checa', code: 'CZ' },
  { name: 'Dinamarca', code: 'DK' },
  { name: 'Yibuti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Republica Dominicana', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egipto', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Guinea Ecuatorial', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Etiopía', code: 'ET' },
  { name: 'Islas Faroe', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finlandia', code: 'FI' },
  { name: 'Francia', code: 'FR' },
  { name: 'Guayana Francesa', code: 'GF' },
  { name: 'Polinesia Francesa', code: 'PF' },
  { name: 'Tierras Australes y Antárticas Francesas', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Alemania', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Grecia', code: 'GR' },
  { name: 'Groenlandia', code: 'GL' },
  { name: 'Granada', code: 'GD' },
  { name: 'Guadelupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Territorio de las Islas Heard y McDonald', code: 'HM' },
  { name: 'Ciudad del Vaticano', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungria', code: 'HU' },
  { name: 'Islandia', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Irlanda', code: 'IE' },
  { name: 'Isla de Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italia', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japon', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordania', code: 'JO' },
  { name: 'Kazajistan', code: 'KZ' },
  { name: 'Kenia', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Corea del Norte', code: 'KP' },
  { name: 'Corea del Sur', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kirguistán', code: 'KG' },
  { name: 'Laos', code: 'LA' },
  { name: 'Letonia', code: 'LV' },
  { name: 'Libano', code: 'LB' },
  { name: 'Lesoto', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libia', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lituania', code: 'LT' },
  { name: 'Luxemburgo', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malaui', code: 'MW' },
  { name: 'Malasia', code: 'MY' },
  { name: 'Maldivas', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Islas Marshall', code: 'MH' },
  { name: 'Martinica', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauricio', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Estados Federados de Micronesia', code: 'FM' },
  { name: 'Moldavia', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Marruecos', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Birmania', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Paises Bajos', code: 'NL' },
  { name: 'Antillas Neerlandesas', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'Nueva Zelanda', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Isla Norfolk', code: 'NF' },
  { name: 'Islas Marianas del Norte', code: 'MP' },
  { name: 'Noruega', code: 'NO' },
  { name: 'Omán', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palaos', code: 'PW' },
  { name: 'Palestina', code: 'PS' },
  { name: 'Panamá', code: 'PA' },
  { name: 'Papua y Nueva Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Filipinas', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Polonia', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Federacion Rusa', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Santa Helena', code: 'SH' },
  { name: 'San Cristobal y Nieves', code: 'KN' },
  { name: 'Santa Lucía', code: 'LC' },
  { name: 'San Pedro y Miquelón', code: 'PM' },
  { name: 'San Vicente y las Granadinas', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Santo Tomé y Príncipe', code: 'ST' },
  { name: 'Arabia Saudita', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leona', code: 'SL' },
  { name: 'Singapur', code: 'SG' },
  { name: 'Eslovaquia', code: 'SK' },
  { name: 'Eslovenia', code: 'SI' },
  { name: 'Islas Salomon', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'Sudafrica', code: 'ZA' },
  { name: 'Islas Georgias del Sur y Sandwich del Sur', code: 'GS' },
  { name: 'España', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Surinam', code: 'SR' },
  { name: 'Svalbard y Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Suecia', code: 'SE' },
  { name: 'Suiza', code: 'CH' },
  { name: 'Siria', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Tailandia', code: 'TH' },
  { name: 'Timor Oriental', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad y Tobago', code: 'TT' },
  { name: 'Tunez', code: 'TN' },
  { name: 'Turquia', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Islas Turcas y Caicos', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ucrania', code: 'UA' },
  { name: 'Emiratos Árabes Unidos', code: 'AE' },
  { name: 'Reino Unido', code: 'GB' },
  { name: 'Estados Unidos de America', code: 'US' },
  { name: 'Islas Ultramarinas Menores de los Estados Unidos', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Islas Vírgenes Británicas', code: 'VG' },
  { name: 'Islas Vírgenes de los Estados Unidos', code: 'VI' },
  { name: 'Wallis y Futuna', code: 'WF' },
  { name: 'Sahara Occidental', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabue', code: 'ZW' },
];
